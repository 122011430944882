import paper from "paper";

function inside(point, w, h) {
    if (point.x < 0) {
        point.x = 0
    } else if (point.x > w) {
        point.x = w
    }
    if (point.y < 0) {
        point.y = 0
    } else if (point.y > h) {
        point.y = h
    }
    return point
}

const toolMove = (scope) => {
    const tool = new scope.Tool()
    tool.name = 'move'

    tool.onKeyDown = function (event) {
        let selected = scope.project.getItems({
            selected: true,
            class: scope.Path
        })
        switch (event.key) {
            case "delete":
                selected.forEach(function (element) {
                    element.remove()
                })
                break;
            case "backspace":
                selected.forEach(function (element) {
                    element.remove()
                })
                break;
        }
    }


    tool.onMouseDrag = function (event) {
        let scope = this.scope
        let w = this.w
        let h = this.h

        let hitResult = null
        hitResult = scope.project.hitTest(event.point)
        if (hitResult) {
            hitResult.item.selected = true
        } else {
            scope.project.deselectAll();
        }
        let selected = scope.project.getItems({
            selected: true,
            class: scope.Path
        })
        selected.forEach(function (element) {
            let move  = true
            let new_x = element.position._x + event.delta.x
            let new_y = element.position._y + event.delta.y
            element.segments.forEach(function(segment){
               if ( ! inside(segment.point, w, h) ){
                   move =false
                 }
            })
            if (move) {
                let new_position = inside(new scope.Point(new_x, new_y), w, h)
                element.position = new_position
            }
        
        })
    }

    tool.setWH = function (scope, w, h) {
        this.scope = scope;
        this.w = w;
        this.h = h;
    }
    return tool
}

export default toolMove;