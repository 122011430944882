














































































































import Vue from "vue";
import { mapState, mapActions } from "vuex";

import { Plotly } from "vue-plotly";
import 'plotly.js/dist/plotly-locale-fr.js';

import { ExitGroup } from "gs-proto/js/gs/proto/program_data_pb";

import { fields_meta } from "gs-proto/js/gs/proto/categories_data_meta";
import { Clazz, Package } from "gs-proto/js/gs/proto/program_data_pb";
import { DroppingRelation } from "gs-proto/js/gs/proto/program_data_pb";

import { State } from "@/store/model";

import { cloneDeep } from "lodash";
import draggable from "vuedraggable";

import { PropType } from "vue/types/umd";
import ClassInfo from "./ClassInfo.vue";

export default Vue.extend({
  components: {
    Plotly
  },
  data() {
    return {
      drops: [] as any,
      fields_meta,
      dialog: false,
      // nodes:{},
      // edges:[],
      layout: {
        font: {
          size: 12
        },
        height: 200,
        margin: {
          t: 10,
          r: 5,
          l: 5,
          b: 10
        }
      },
      plot_data_sankey: [],
      rem: true
    };
  },
  props: {
    element: {
      type: Object
    },
    // tmp_current_groups: { type: Array as PropType<Array<ExitGroup.AsObject>> },
    // tmp_droppingRelationsList: {
    //   type: Array as PropType<Array<DroppingRelation.AsObject>>
    // }
  },
  computed: {
    ...mapState({
      current_program: state => (state as State).program,
      machine: state => (state as State).machine,
      all_packages: state => (state as State).packages as Package.AsObject[],
      tmp_droppingRelationsList: state => (state as State).tmp_droppingRelationsList || [],
      tmp_current_groups: state => (state as State).tmp_current_groups || []
    })
  },

  watch: {
    dialog(v) {
      this.get_drops();
      // this.get_flowchart_data();
      this.get_sankey_plot();
    }
  },
  methods: {
    get_remainings() {
      let remainings = {};
      let classes = (this as any).current_program.objectCategoryProgramsList[0]
        .classesList;
      for (let i in classes) {
        remainings[classes[i].id] = 100;
      }
      this.tmp_droppingRelationsList.forEach(dr => {
        dr.classPercentageList.forEach(cp => {
          if (dr.mixOption == 1) {
            if (cp.percentage == -1) {
              remainings[cp.classId] -= 100;
            } else {
              remainings[cp.classId] -= cp.percentage;
            }
          }
        });
      });
      let final_remainings = [] as any;

      for (let i in remainings) {
        if (remainings[i] > 0) {
          final_remainings.push(
            classes.filter(x => x.id == i)[0].name + " (% unassigned)"
          );
        }
      }
      if (final_remainings.length > 0) {
        return final_remainings.join(" + ");
      } else {
        return "";
      }
    },

    get_remaining_exits() {
      let remainings = [] as any;
      this.tmp_droppingRelationsList.forEach(dr => {
        dr.classPercentageList.forEach(cp => {
          if (cp.classId == 0) {
            remainings.push("Exit " + dr.exitId);
          }
        });
      });
      (this as any).machine.exitsList.forEach(e => {
        if (e.state == 4) {
          remainings.push("Exit " + e.id);
        }
      });
      return remainings;
    },

    get_drops() {
      let drops = [] as any;
      let class_id = this.element.id;
      let groups = (this as any).tmp_current_groups;
      let drops_rl = this.tmp_droppingRelationsList;
      let remaining = 100;

      this.tmp_droppingRelationsList.forEach(dr => {
        let mixOption = dr.mixOption;

        let exitId = dr.exitId;
        let is_dinamic = false;

        if (dr.exitId == 0) {
          exitId = groups.filter(x => x.id == dr.groupId)[0].name;
          is_dinamic = drops_rl.filter(x => x.groupId == dr.groupId).length > 1;
        }

        dr.classPercentageList.forEach(cp => {
          if (cp.classId == class_id) {
            if (mixOption == 0) {
              drops.push({
                exit: "Exit " + exitId,
                percentage: 0,
                is_mix: cp.percentage,
                is_dinamic: is_dinamic,
                package: dr.packageId
              });
            } else {
              if (cp.percentage == -1) {
                remaining -= 100;
                drops.push({
                  exit: "Exit " + exitId,
                  percentage: 0,
                  is_mix: false,
                  is_dinamic: is_dinamic,
                  package: dr.packageId
                });
              } else {
                remaining -= cp.percentage;
                drops.push({
                  exit: "Exit " + exitId,
                  percentage: cp.percentage,
                  is_mix: false,
                  is_dinamic: is_dinamic,
                  package: dr.packageId
                });
              }
            }
          }
        });
      });

      if (remaining > 0) {
        let r = (this as any).get_remaining_exits();
        if (r.length > 0) {
          drops.push({
            exit: r.join(" + "),
            percentage: -1,
            is_mix: false,
            is_dinamic: false,
            package: 0
          });
        }
      }
      this.drops = drops;
    },

    // get_flowchart_data(){
    //   let nodes = {};
    //   let edges = [] as any;

    //   let index = 0;
    //   nodes[index] = { text: (this as any).element.name };
    //   index +=1

    //   let groups = (this as any).tmp_current_groups;
    //   let remaining = 100;
    //   let reamining_exits = (this as any).get_remaining_exits();
    //   let class_id = this.element.id;


    //   this.tmp_droppingRelationsList.forEach(dr => {
    //     let mixOption = dr.mixOption;
    //     let exitId = dr.exitId;

    //     if (dr.exitId == 0) {
    //       exitId = groups.filter(x => x.id == dr.groupId)[0].name;
    //     }
    //     nodes[index] = { text: "Exit " + exitId };
    //     index +=1;
    //     dr.classPercentageList.forEach(cp => {
    //       if (cp.classId == class_id) {
    //         if (mixOption == 0) {
    //           nodes[index] = { text: "Mix (" + cp.percentage + '%)' };// (" + cp.percentage + '%)');
    //           index +=1;
    //           edges.push( {from: "0", to:String(index-1), text: '' });
    //           edges.push( {from: String(index-1), to:String(index-2), text:  '' });
    //         } else {
    //           if (cp.percentage == -1) {
    //             remaining -= 100;
    //             edges.push( {from: '0', to: String(index-1), text:  ' % unassigned' });
    //           } else {
    //             remaining -= cp.percentage;
    //             edges.push( {from: '0', to: String(index-1), text:  cp.percentage + '% incomingflow' });
    //           }
    //         }
    //       }
    //      });
    //   });

    //   if (remaining > 0) {
    //     nodes[index] = { text: reamining_exits};
    //     edges.push( {from: '0', to: String(index), text:  ' % unassigned' });
    //   }
    //   (this as any).nodes = nodes;
    //   (this as any).edges = edges;
    // },

    get_sankey_plot() {
      let new_label = [] as any;
      let new_color = [] as any;

      let new_source = [] as any;
      let new_target = [] as any;
      let new_value = [] as any;
      let new_link_color = [] as any;

      let index = 0;
      new_label.push((this as any).element.name);
      new_color.push("green");

      let drops_rl = this.tmp_droppingRelationsList;
      let class_id = this.element.id;
      let groups = (this as any).tmp_current_groups;
      let remaining = 100;
      let reamining_exits = (this as any).get_remaining_exits();

      this.tmp_droppingRelationsList.forEach(dr => {
        let mixOption = dr.mixOption;
        let exitId = dr.exitId;

        if (dr.exitId == 0) {
          exitId = groups.filter(x => x.id == dr.groupId)[0].name;
        }
        new_label.push("Exit " + exitId);
        new_color.push("#b2e688");
        index += 1;

        dr.classPercentageList.forEach(cp => {
          if (cp.classId == class_id) {
            if (mixOption == 0) {
              new_label.push("Mix"); // (" + cp.percentage + '%)');
              new_color.push("#fed894");
              index += 1;

              new_source.push(0);
              new_target.push(index);
              new_value.push(1);
              new_link_color.push("rgba(254,216,148, 0.5)");

              new_source.push(index);
              new_target.push(index - 1);
              new_value.push(1);
              new_link_color.push("rgba(254,216,148, 0.5)");
            } else {
              if (cp.percentage == -1) {
                remaining -= 100;
              } else {
                remaining -= cp.percentage;
              }
              new_source.push(0);
              new_target.push(index);
              new_value.push(1);
              new_link_color.push("rgba(2,195,54, 0.3)");
            }
          }
        });
      });

      if (remaining > 0) {
        new_label.push(reamining_exits);
        new_color.push("#cef4f2");
        index += 1;
        new_source.push(0);
        new_target.push(index);
        new_value.push(1);
        new_link_color.push("rgba(100,219,241, 0.3)");
      }

      (this as any).plot_data_sankey = [
        {
          type: "sankey",
          orientation: "h",
          node: {
            pad: 15,
            thickness: 30,
            line: {
              color: "black",
              width: 0.5
            },
            label: new_label,
            color: new_color
          },

          link: {
            source: new_source,
            target: new_target,
            value: new_value,
            color: new_link_color
          }
        }
      ];
    }
  }
  
});
