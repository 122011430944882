<template>
  <v-dialog
    @keydown.enter="my_dialog = false"
    v-model="my_dialog"
    :width="selected_column.type == 'color' ? 1300 : 500"
    v-if="selected_value && !selected_value.id == 0"
  >

    <!-- Color -->
    <slider-color v-if="selected_column.id == 40001" :dialog="my_dialog" :selected_column="selected_column" :selected_value="selected_value"/>
    <!-- Surface -->
    <slider-surface :selected_column="selected_column" :selected_value="selected_value"/>
    <!-- Range -->
    <slider-range :selected_column="selected_column" :selected_value="selected_value"/>

  </v-dialog>
</template>

<script>
import { cloneDeep } from "lodash";
import { constants } from "zlib";
import SliderColor from "./SliderColor";
import SliderRange from "./SliderRange";
import SliderSurface from "./SliderSurface";

export default {
  name: "rowDialog",
  components: {
    SliderColor,
    SliderRange,
    SliderSurface
  },
  props: {
    selected_column: {
      type: Object
    },
    selected_value: {
      type: Object
    },
    dialog: {
      type: Boolean
    }
  },

  data: () => ({
    my_dialog: false,
  }),
  watch: {
    dialog(val) {
      this.my_dialog = val;
    },
    my_dialog(val) {
      this.$emit("dialog", val);
    }
  },
};
</script>

<style>
.modebar {
  display: none !important;
}
</style>
