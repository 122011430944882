import { Clazz, Criterion, ColorRange, ColorSurfaceRange } from "gs-proto/js/gs/proto/program_data_pb";
import { fields_meta, FieldMetaType } from "gs-proto/js/gs/proto/categories_data_meta";

export const ClazzTransformFunctions = {

  toProto(data) {
    let clazzes: Clazz.AsObject[] = []
    data.forEach(function (value, i) {
      let criteria: Criterion.AsObject[] = []
      Object.keys(value).forEach(function (k, _) {
        let field_ids = [...fields_meta.entries()].filter(x => x[1].title == k);
        if (field_ids.length > 0) {
          let field_type = field_ids[0][1].type;
          let field_id: any = field_ids[0][0];

          if (field_type == 1) { //range
            let new_criteria: Criterion.AsObject = {
              field: field_id,
              range: value[k],
              bool: false
            }
            criteria.push(new_criteria);
          }
          if (field_type == 2) { // bool
            let new_criteria: Criterion.AsObject = {
              field: field_id,
              bool: value[k]
            }
            criteria.push(new_criteria)

          }
          if (field_type == 3) { // color
            let cr: ColorRange.AsObject = {
              minHue: value[k][0],
              maxHue: value[k][1]
            }

            let new_criteria: Criterion.AsObject = {
              field: field_id,
              colorRange: cr,
              bool: false
            }
            criteria.push(new_criteria);
          }

          if (field_type == 4) { // color surface
            let csr: ColorSurfaceRange.AsObject = {
              minSurface: value[k][0],
              maxSurface: value[k][1]
            }
            let new_criteria: Criterion.AsObject = {
              field: field_id,
              colorSurfaceRange: csr,
              bool: false
            }
            criteria.push(new_criteria);
          }
        }
      })
      if (value.id > 0) {
        let new_clazz: Clazz.AsObject = {
          id: value.id,
          name: value.name,
          criteraList: criteria
        }
        clazzes.push(new_clazz)
      }
    });
    return clazzes
  },

  toObject(clazzes) {
    let tableData: any = []
    clazzes.forEach(clazz => {
      let new_class_table = {}
      new_class_table['id'] = clazz.id
      new_class_table['name'] = clazz.name

      clazz.criteraList.forEach(criteria => {
        if (fields_meta.get(criteria.field) != undefined) {
          let field: FieldMetaType = fields_meta.get(criteria.field)!

          if (field.title != undefined) {
            if (field.type == 1) { //range
              let minValue = criteria.range['minValue']
              let maxValue = criteria.range['maxValue']
              new_class_table[field.title] = [minValue, maxValue]
            }
            if (field.type == 2) { // bool
              let value = criteria.bool
              new_class_table[field.title] = value
            }
            if (field.type == 3) { // color
              let criteria_color_range = criteria['colorRange'];
              new_class_table[field.title] =[
                criteria_color_range.minHue,
                criteria_color_range.maxHue
              ]
            }

            if (field.type == 4) { // color surface
              let criteria_color_surface_range = criteria['colorSurfaceRange'];
              new_class_table[field.title] =[
                criteria_color_surface_range.minSurface,
                criteria_color_surface_range.maxSurface,
              ]
            }
          }
        }
      })
      tableData.push(new_class_table);
    })
    return tableData
  }
}
