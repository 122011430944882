<template>
  <v-card v-if="selected_column.type == 'colorsurface'" class="pa-3 pb-5">
    <v-list-item>
      <v-list-item-content>
        <div class="text-overline mb-1">{{ selected_value.name }}</div>
        <v-list-item-title
          class="text-capitalize text-h6 mb-1"
        >{{$t('proto.'+ selected_column.title.toLowerCase() )}}</v-list-item-title>
        <v-list-item-subtitle>
          <small>( % {{$t('classification.surface')}} )</small>
        </v-list-item-subtitle>
      </v-list-item-content>

      <v-btn rounded outlined small @click="no_use()">{{$t('classification.no_use')}}</v-btn>
    </v-list-item>
    <v-list-item-content>
      <div class="ma-5">
        <v-range-slider
          v-model="selected_value[selected_column.field]"
          :min="0"
          :max="100"
          step="1"
          hide-details
          class="align-center"
        >
          <template v-slot:prepend>
            <v-text-field
              :value="selected_value[selected_column.field][0]"
              :min="0"
              :max="100"
              class="mt-0 pt-0"
              hide-details
              single-line
              type="number"
              step="1"
              style="width: 60px"
              @change="change_range_min"
            ></v-text-field>
          </template>

          <template v-slot:append>
            <v-text-field
              :value="selected_value[selected_column.field][1]"
              :min="0"
              :max="100"
              class="mt-0 pt-0"
              hide-details
              single-line
              type="number"
              step="1"
              style="width: 60px"
              @change="change_range_max"
            ></v-text-field>
          </template>
        </v-range-slider>
      </div>
    </v-list-item-content>
  </v-card>
</template>


<script>
export default {
  name: "sliderRange",
  data() {
    return {};
  },
  props: {
    selected_column: {
      type: Object
    },
    selected_value: {
      type: Object
    }
  },
  updated() {
    console.log(this.selected_value[this.selected_column.field]);
  },
  methods: {
    change_range_min(event) {
      let val = parseInt(event);
      let range_max = parseInt(
        this.selected_value[this.selected_column.field][1]
      );

      if (event < range_max) {
        this.$set(this.selected_value[this.selected_column.field], 0, val);
        return val;
      } else {
        this.$set(
          this.selected_value[this.selected_column.field],
          0,
          range_max
        );
        return range_max;
      }
    },
    change_range_max(event) {
      let val = parseInt(event);
      let range_min = parseInt(
        this.selected_value[this.selected_column.field][0]
      );

      if (event > range_min) {
        this.$set(this.selected_value[this.selected_column.field], 1, val);
        return val;
      } else {
        this.$set(
          this.selected_value[this.selected_column.field],
          1,
          range_min
        );
        return range_min;
      }
    },

    no_use() {
      this.$set(this.selected_value[this.selected_column.field], 0, 0);
      this.$set(this.selected_value[this.selected_column.field], 1, 0);
    }
  }
};
</script>


