import { Store } from "vuex";
import { State } from "../model";
import { UIEvent } from "gs-proto/js/gs/proto/ui_data_pb";
import initial_state from "../state";

// Only reads from the backend.
// After a minimal validation logic, the recieved data is commited.
const wsConnect = function (store: Store<State>): void {
  console.log("wsConnect :: starting :: store=", store);
  let prefix = "ws://";
  if (window.location.protocol == "https:") {
    prefix = "wss://";
  }
  const wsAddress =
    prefix +
    window.location.hostname +
    ":" +
    window.location.port +
    "/events/";
  const connection = new WebSocket(wsAddress);
  console.log(".. (wsConnect) Connecting to " + wsAddress);

  connection.onclose = (event) => {
    console.log("-- (wsConnect) Connection closed " + event.code + event.reason);
    store.commit("disconnected");
    // setTimeout(wsConnect, 1200);
    setTimeout(function() {
      console.log(".. Trying to reconnect to " + wsAddress);
      wsConnect(store);
    }, 1200);
  };

  connection.onopen = () => {
    console.log("++ (wsConnect) Connected to " + wsAddress);
    store.commit("connected");
  };

  connection.onmessage = (event) => {
    // console.log('new event', event);
    if (event.data instanceof Blob) {
      const bufferPromise = event.data.arrayBuffer();
      bufferPromise.then((buffer: ArrayBuffer) => {
        // console.log('new message from websocket')
        const ui_event = UIEvent.deserializeBinary(new Uint8Array(buffer));
        store.commit("apply_ui_event", ui_event);
      
      });
    } else {
      console.log("!! (wsConnect) Unexpected received data: " + typeof event.data);
      console.log(event.data);
    }
  };

  connection.onerror = (event) => {
    console.log("!! (wsConnect) Error connecting to" + wsAddress);
    console.warn("ws::error: ", event);
  };
};

export default wsConnect;
