













import Vue from "vue";

export default Vue.extend({
  name: "grid",
  data: () => ({
    tmp_cols: 0
  }),
  props: {
    cols: {
      type: Number
    }
  },
  mounted() {
    this.tmp_cols = this.cols;
  },

  watch: {
    tmp_cols(val) {
      this.$emit("cols", val);
    }
  },
  methods: {}
});
