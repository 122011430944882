






























































import Vue from "vue";
import { mapState, mapActions } from "vuex";
import { State, MachineSettings } from "@/store/model";
import InfoSlider from "./info/InfoSlider.vue";
import { MachineComponent } from "gs-proto/js/gs/proto/machine_data_pb";
import { machine_component_categories_meta} from "gs-proto/js/gs/proto/categories_data_meta";

export default Vue.extend({
  name:"componentSlider",
  components:{
      InfoSlider
  },
  props:{
      componentTitle :{
        type: String
      },
      icon :{
        type: String
      },
      min_speed:{
        type: Number
      },
      max_speed:{
        type: Number
      },
  },
  data: () => ({
    machine_component_categories_meta,
    no_data: 'X',
    speed: 0, // TODO
    step: 0.01,
    component_category: -1
  }),

  computed: {
    ...mapState({
        machineComponents:  state => (state as State).machine?.componentsList as MachineComponent.AsObject[],
        tmp_machine_settings: state => (state as State).tmp_machine_settings as MachineSettings,
    }),
    current_value(){
      if( (this.machineComponents) &&  (this.machineComponents.filter(x=>x.category==this.component_category).length > 0)  ){
        return (this as any).machineComponents.filter(x=>x.category==this.component_category)[0].pb_double.toFixed(2)
      }
      return (this as any).no_data;
    },
    same_speed(){
        return (this as any).speed == this.current_value;
    },
     color() {
      if (this.speed < this.max_speed / 5) return "indigo";
      if (this.speed < this.max_speed / 4) return "teal";
      if (this.speed < this.max_speed / 3) return "green";
      if (this.speed < this.max_speed / 2) return "orange";
      return "red";
    },
  },
  mounted(){
      (this as any).machine_component_categories_meta.forEach(x=> {
        if (x.title==this.componentTitle){
          this.component_category = x.id;}
      });
      (this as any).speed = (this as any).current_value;
  },

  methods: {
    ...mapActions(["store_tmp_machine_settings", "change_machine_settings_double", "change_machine_settings_bool"]), // TODO 
    
    restart(){ 
      console.log('restarting...',  (this as any).speed , (this as any).current_value);
      (this as any).speed = (this as any).current_value;
    },

    send_new_value($event){
      let v = (this as any).speed;
      if ((v >= (this as any).min_speed) &&  (v <= (this as any).max_speed)) {
        let _tmp_machine_settings = (this as any).tmp_machine_settings;
        _tmp_machine_settings[(this as any).componentTitle] = v;
        (this as any).store_tmp_machine_settings(_tmp_machine_settings);
        (this as any).change_machine_settings_double([this.component_category, v]) 
      }
    },
    decrement() {
      if (this.speed - this.step >= this.min_speed){
          this.speed -= this.step;
          (this as any).send_new_value( this.speed);
      }
    },
    increment() {
       if (this.speed + this.step <= this.max_speed){
           this.speed += this.step;
           (this as any).send_new_value( this.speed);
      }
    }
  }
});
