<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-icon class="ml-2" color="gray" v-bind="attrs" v-on="on" small>mdi-information-outline</v-icon>
    </template>

    <v-card>
      <v-card-title>{{$t('distribution.mixing')}} {{$t('info')}}</v-card-title>

      <v-card-text>
        <div>
          <b>{{$t('distribution.mix_exit_flow')}} : </b>
          {{$t('distribution.mix_exit_flow_info')}} 
         
        </div>
        <div>
          <b>{{$t('distribution.mix_incoming_flow')}}: </b>
          {{$t('distribution.mix_incoming_flow_info')}}
         
        </div>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false">{{$t('continue')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false
    };
  }
};
</script>