




















import Vue from "vue";

export default Vue.extend({
  name: "cancelSnackbar",
  data() {
    return {
      snackbar: false
    };
  },
  props: {
    snackbar_cancel: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    snackbar_cancel(v) {
      this.snackbar = v;
    },
    snackbar(v) {
      this.$emit("snackbar", v);
    }
  },
  methods: {
    cancel() {
      this.snackbar = false;
      this.$emit("snackbar", false);
      this.$emit("cancel");
    },
    contin() {
      this.snackbar = false;
      this.$emit("snackbar", false);
      this.$emit("continue");
    }
  }
});
