







































































































































































































































































import Vue from "vue";
import { mapState, mapActions } from "vuex";
import { cloneDeep } from "lodash";
import { PropType } from "vue/types/umd";

import {
  DroppingRelation,
  Package
} from "gs-proto/js/gs/proto/program_data_pb";

import { State } from "@/store/model";

import PackageOption from "./options/PackageOption.vue";
import FillDialog from "./info_dialogs/InfoFillDialog.vue";
import MixingDialog from "./info_dialogs/InfoMixingDialog.vue";

export default Vue.extend({
  name: "mixEdit",
  components: {
    // InfoDrop,
    PackageOption,
    FillDialog,
    MixingDialog
  },

  data: () => ({
    dialog: false,
    checkbox_mix: true,
    mix_option: 0, //TODO
    tmp_classes: [],
    tmp_drop_exit: {} as DroppingRelation.AsObject
  }),

  props: {
    drop_exit: {
      type: Object
    },
    exit: {
      type: Object
    },
    is_group: {
      type: Boolean
    },
    classes: {
      type: Array
    },
    colors: {
      type: Object
    }
  },
  watch: {
    tmp_droppingRelationsList(v) {
      this.tmp_drop_exit = cloneDeep(
        v.filter(x => x.id == this.drop_exit.id)[0]
      );
      this.tmp_drop_exit = cloneDeep(this.drop_exit);
    }
  },
  computed: {
    ...mapState({
      tmp_droppingRelationsList: state =>
        (state as State).tmp_droppingRelationsList || [],
      tmp_current_groups: state => (state as State).tmp_current_groups || [],
      all_packages: state => (state as State).packages as Package.AsObject[]
    }),

    fill_options() {
      let tmp_drop_exit = this.tmp_drop_exit;
      let n_products = this.tmp_droppingRelationsList.filter(
        x => x.groupId == tmp_drop_exit.groupId
      ).length;

      if (n_products == 1) {
        return [
          { value: 0, text: (this as any).$t("distribution.onebyone") },
          { value: 1, text: (this as any).$t("distribution.simultaneously") }
        ];
      }
      return [
        { value: 0, text: (this as any).$t("distribution.onebyone") },
        { value: 2, text: (this as any).$t("distribution.dynamic") }
      ];
    },

    mix_options() {
      return [
        { value: 0, text: (this as any).$t("distribution.mix_exit_flow") },
        { value: 1, text: (this as any).$t("distribution.mix_incoming_flow") }
      ];
    }
  },
  mounted() {
    this.tmp_drop_exit = cloneDeep(this.drop_exit);
    this.tmp_classes = cloneDeep(this.classes);
    (this as any).sortByPercentage();
  },

  methods: {
    suffix(cl) {
      let drop_package = (this as any).all_packages.filter(
        x => x.id == (this as any).tmp_drop_exit.packageId
      )[0];
      let percentage = (this as any).get_mix_by_id(cl.id).percentage / 100;
      if (drop_package["pieces"] > 0) {
        return (
          "%   (" +
          (percentage * drop_package["pieces"]).toFixed(1) +
          " pieces )"
        );
      }
      return (
        "%   (" + (percentage * drop_package["weight"]).toFixed(2) + " kg )"
      );
    },

    change_package(v) {
      (this as any).tmp_drop_exit.packageId = v;
      if (v == 0) {
        (this as any).tmp_drop_exit.mixOption = 1;
      }
      (this as any).correct_mixes((this as any).tmp_drop_exit.mixOption);
    },

    is_mix_inflow() {
      return (this as any).drop_exit.mixOption == 0;
    },

    not_covered_percentage(elem_id) {
      let covered_percentage = 0;
      let current_id = (this as any).drop_exit.id;
      (this as any).tmp_droppingRelationsList
        .filter(x => x.mixOption == 1)
        .forEach(dr => {
          if (dr.id != current_id) {
            dr.classPercentageList
              .filter(c => c.classId == elem_id)
              .forEach(cl => {
                if (cl.percentage == -1) {
                  // covered_percentage += 100;
                } else {
                  covered_percentage += cl.percentage;
                }
              });
          }
        });
      return covered_percentage <= 100 ? 100 - covered_percentage : 0;
    },

    get_mix_by_id(id) {
      if (
        this.tmp_drop_exit.classPercentageList.map(x => x.classId).includes(id)
      ) {
        return this.tmp_drop_exit.classPercentageList.filter(
          x => x.classId == id
        )[0];
      }
      return { percentage: 0 };
    },

    edit() {
      this.dialog = true;
    },

    cancel() {
      this.dialog = false;
    },

    apply() {
      if ((this as any).check_mix_correct()) {
        (this as any).$emit("drop_exit", (this as any).tmp_drop_exit);
      }
    },

    sortByPercentage() {
      let temp_mix = this.tmp_drop_exit.classPercentageList;
      this.tmp_classes.sort(function(first, second) {
        function percentage_by_id(c) {
          let tmp = temp_mix.filter(x => x.classId == c.id);
          return tmp.length > 0 ? tmp[0].percentage : 0;
        }
        return percentage_by_id(second) - percentage_by_id(first);
      });
    },

    sortByName() {
      this.tmp_classes.sort(function(first: any, second: any) {
        return second.name > first.name ? -1 : 1;
      });
    },

    sum_percentages() {
      if (this.tmp_drop_exit.classPercentageList) {
        return this.tmp_drop_exit.classPercentageList
          .map(x => x.percentage)
          .reduce((a, b) => a + b);
      }
      return 0;
    },

    change(id, event) {
      if (
        this.tmp_drop_exit.classPercentageList.map(x => x.classId).includes(id)
      ) {
        if (this.tmp_drop_exit.mixOption == 0) {
          // check for mix option = mix_exit_flow
          let per =
            100 -
            this.tmp_drop_exit.classPercentageList
              .filter(x => x.classId != id)
              .map(x => x.percentage)
              .reduce((a, b) => a + b);
          
          let drop_package = (this as any).all_packages.filter(
            x => x.id == (this as any).tmp_drop_exit.packageId
          )[0];

          let percentage = (this as any).get_mix_by_id(id).percentage / 100;
          if (drop_package["pieces"] > 0) {
            let num = percentage * drop_package["pieces"];
            if (!!(num % 1)) {
              event = (Math.trunc(num) / drop_package["pieces"]) * 100;
            }
          }
           event = per > event ? event : per;

        } else {
          // check for mix option = mix_incoming_flow
          let sum = 0;
          this.tmp_droppingRelationsList
            .filter(x => x.id != this.drop_exit.id)
            .filter(x => x.mixOption == 1)
            .map(x => x.classPercentageList)
            .forEach(c => {
              let per =
                c.filter(x => x.classId == id).length > 0
                  ? c.filter(x => x.classId == id)[0].percentage
                  : 0;
              sum += per;
            });
          sum = 100 - sum;
          event = sum > event ? event : sum;
        }
        this.tmp_drop_exit.classPercentageList.filter(
          x => x.classId == id
        )[0].percentage = event;
      } else {
        this.tmp_drop_exit.classPercentageList.push({
          classId: id,
          percentage:
            this.tmp_drop_exit.mixOption == 0
              ? 100 - (this as any).sum_percentages()
              : event
        });
      }
    },
    correct_mixes(event) {
      if (event == 1) {
        this.drop_exit.classPercentageList.forEach(cl => {
          this.tmp_drop_exit.classPercentageList.filter(
            x => x.classId == cl.classId
          )[0].percentage = -1;
        });
      } else {
        // check for mix option = mix_exit_flow
        for (
          var i = 0;
          i < this.tmp_drop_exit.classPercentageList.length;
          i++
        ) {
          this.tmp_drop_exit.classPercentageList[i].percentage = 0;
        }
      }
      (this as any).sortByPercentage();
    },

    check_mix_correct() {
      if (this.tmp_drop_exit.mixOption == 0) {
        if ((this as any).sum_percentages() == 100) {
          let drop_package = (this as any).all_packages.filter(
            x => x.id == (this as any).tmp_drop_exit.packageId
          )[0];
          if (drop_package["pieces"] > 0) {
            (this as any).tmp_drop_exit.classPercentageList.forEach(x => {
              let d = x['percentage'] * drop_package["pieces"];
              if (!!( d % 1)){
                return false;
              }
            });
          }
          return true;
        }
        return false;
      }
      return true;
    }
  }
});
