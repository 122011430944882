// index.js
import en from "./en.json";
import nl from "./nl.json";

export const defaultLocale = "en";

export const languages = {
  en: en,
  nl: nl,
};
