// https://next.vuex.vuejs.org/guide/getters.html

import { State, StatusSummary } from "./model";
import { Problem } from "gs-proto/js/gs/proto/commons_data_pb";
import { ObjectCategoryMap, FieldTypeMap, PackageType } from "gs-proto/js/gs/proto/categories_data_pb";
import { ObjectProfileMetaType, object_profiles_meta, FieldMetaType, fields_meta } from "gs-proto/js/gs/proto/categories_data_meta";
import { MachineState } from "gs-proto/js/gs/proto/machine_data_pb";

const machine_states_map = {};
(Object.keys(MachineState) as Array<keyof typeof MachineState>).map((key, i) => {
  machine_states_map[i] = key;
})
console.log(machine_states_map);

export default {
  machineProblemsTypes: (state: State): Object => {
    return {
      0: { type: "FATAL", color: "red" },
      1: { type: "ERROR", color: "red" },
      2: { type: "WARNING", color: "orange" },
      3: { type: "INFO", color: "green" },
      4: { type: "DEBUG", color: "blue" }
    }
  },

  getTmpDroppingRelationsList: state => localStorage.tmp_droppingRelationsList || [],
  getTmpCurrentGroups: state => localStorage.tmp_current_groups || [],
  getTmpCurrentFilters: state => localStorage.tmp_current_filters || [],

  statusSummary: (state: State): StatusSummary => {
    let machine_state = "NOT FOUND";

    if (state.machine) {
      machine_state = machine_states_map[state.machine.state];
    }

    if (!state.ws_connected) {
      return {
        state: machine_state,
        label: "status.disconnected",
        color: "error",
        icon: "mdi-alert-circle"
      };
    }
    if (state.reply_commons) {
      const problems = state.reply_commons.problemsList;
      if (problems.length > 0) {
        let most_critical_error: Problem.TypeMap[keyof Problem.TypeMap] =
          Problem.Type.DEBUG;
        for (const problem of problems) {
          if (problem.type < most_critical_error) {
            if (problem.component != "") { most_critical_error = problem.type; }
          }
        }
        if (most_critical_error < Problem.Type.WARNING) {
          return {
            state: machine_state,
            label: "status.error",
            color: "error",
            icon: "mdi-alert-circle",
          };
        } else if (most_critical_error == Problem.Type.WARNING) {
          return {
            state: machine_state,
            label: "status.warning",
            color: "warning",
            icon: "mdi-alert",
          };
        }
      }
    }
    // TODO
    return {
      state: machine_state,
      label: "status.ready",
      color: "success",
      icon: "mdi-check-circle",
    };
  },
  availableProducts: (state: State): ObjectProfileMetaType[] => {
    let res: ObjectProfileMetaType[] = [];
    if (!state.available_programs) {
      return res;
    }
    for (let opm of object_profiles_meta.values()) {
      let opmId = opm.id as ObjectCategoryMap[keyof ObjectCategoryMap];
      for (let prg of state.available_programs) {
        if (prg.objectCategoriesList.includes(opmId)) {
          res.push(opm);
          break
        }
      }
    }
    return res;
  },

  productParentId: (state: State): number => {
    //TODO for now, let asume there is only one product -- error return 0
    if (!state.available_programs) {
      return 0;
    }
    let program_id = state.program ? state.program.id : 0
    let obj_id = state.available_programs.filter(x => x.id == program_id)[0].objectCategoriesList[0]
    let o = object_profiles_meta.get(obj_id)
    return o == undefined ? 0 : o.parent
  },

  availableFields: (state: State): FieldMetaType[] => {
    let res: FieldMetaType[] = [];
    for (let fm of fields_meta.values()) {
      res.push(fm)
    }
    return res
    // let res: FieldMetaType[] = [];
    // function get_parent_ids(objSet: Set<number>, object_id: number) {
    //   if (object_profiles_meta.get(object_id)) {
    //     let parent_id = object_profiles_meta.get(object_id).parent
    //     objSet.add(parent_id)
    //     if (parent_id != 0) {
    //       return get_parent_ids(objSet, parent_id)
    //     }
    //   }
    // }
    // if (state.program && state.available_programs) {
    //   let objects_cat = [].concat.apply([], state.available_programs
    //     .filter(x => x.id == state.program.id)
    //     .map(x => x.objectCategoriesList)
    //   )
    //   let objSet = new Set<number>(objects_cat)
    //   objects_cat.forEach(o => {
    //     get_parent_ids(objSet, o)
    //   })
    //   let obj_arr = Array.from(objSet)

    //   for (let fm of fields_meta.values()) {
    //     if (fm.parent == 0){
    //       res.push(fm);
    //     }else{
    //       let intersection_obj  = fm.oc_specs.map(x=>x.oc).filter(value => obj_arr.includes(value))
    //       if (intersection_obj.length > 0) { 
    //         res.push(fm);
    //       }
    //     }
    //   }
    // }
    // return res
  },
  packagesMap: (state: State): Object => {
    return PackageType
  }
};
