// https://next.vuex.vuejs.org/guide/state.html

import { State , MachineSettings} from "./model";

const initial_state: State = {
  alerts: [],
  ws_connected: false,
  tmp_current_groups: [],
  tmp_droppingRelationsList:[],
  tmp_machine_settings: {},
  tmp_current_filters: [],
  problemsQueue:[]
};

export default initial_state;
