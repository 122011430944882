<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-icon x-small class="ml-2" color="gray" v-bind="attrs" v-on="on" small>mdi-information-outline</v-icon>
    </template>
    <v-card>
      <v-card-title>  <h5> {{title}} </h5> </v-card-title>
      <v-card-text>
          {{text}}
      </v-card-text>
      <v-divider></v-divider>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false
    };
  },
  props:{
    title:{
      type: String
    },
    text:{
      type: String
    }
  },
};
</script>