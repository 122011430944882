var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","width":"700","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',[_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"small":"","color":"primary","outlined":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-table-column-plus-after")]),_vm._v(" "+_vm._s(_vm.$t('classification.fields_of_interest'))+" ")],1)],1)]}}]),model:{value:(_vm.fields_dialog),callback:function ($$v) {_vm.fields_dialog=$$v},expression:"fields_dialog"}},[_c('v-card',[_c('v-card-title',[_c('v-btn',{attrs:{"outlined":"","x-small":"","fab":"","color":"#4e80ae"}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-table-column-plus-after")])],1),_c('h6',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.$t('classification.fields_of_interest')))])],1),_c('v-card-text',{staticStyle:{"height":"90%"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.temp_columns.filter(function (t) { return ( (t.parent!==0) && (t.type!=='out') || ((t.parent==0)&& (t.type!='out'))); }),"items-per-page":5,"group-by":"parent","show-group-by":"","hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
var headers = ref.headers;
var toggle = ref.toggle;
var isOpen = ref.isOpen;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-btn',{ref:group,attrs:{"x-small":"","icon":""},on:{"click":toggle}},[(isOpen)?_c('v-icon',[_vm._v("mdi-plus")]):_c('v-icon',[_vm._v("mdi-minus")])],1),(group != 0)?_c('span',{staticClass:"mx-5 font-weight-bold text-capitalize"},[_vm._v(_vm._s(_vm.$t('proto.' + _vm.temp_columns.filter(function (x){ return x.id== group; })[0].title )))]):_c('span',{staticClass:"mx-5 font-weight-bold text-capitalize"},[_vm._v("Other fields")])],1)]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [(item.active)?_c('span',{staticClass:"text-capitalize"},[_c('b',[_vm._v(_vm._s(_vm.$t('proto.' +item.title)))])]):_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.$t('proto.' +item.title)))])]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{staticClass:"messages-off",attrs:{"disabled":item.type=='out'},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})]}},{key:"item.unit",fn:function(ref){
var item = ref.item;
return [(item.oc_specs.length > 0)?_c('span',[_vm._v(" "+_vm._s(item.oc_specs[0].range.unit)+" ")]):_vm._e()]}},{key:"item.fixed",fn:function(ref){
var item = ref.item;
return [_c('v-select',{staticClass:"messages-off",staticStyle:{"font-size":"12px"},attrs:{"dense":"","no-gutter":"","outlined":"","item-value":"value","item-text":"text","items":_vm.pinned_values},model:{value:(item.fixed),callback:function ($$v) {_vm.$set(item, "fixed", $$v)},expression:"item.fixed"}})]}}])})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.cancel}},[_vm._v(_vm._s(_vm.$t('cancel')))]),_c('v-spacer'),_c('v-btn',{staticClass:"pa-3",attrs:{"color":"primary"},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.$t('apply')))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }