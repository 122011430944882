<template>
  <v-dialog scrollable v-model="fields_dialog" width="700" persistent>
    <template v-slot:activator="{ on, attrs }">
      <div>
        <v-btn small color="primary" class="ml-2" outlined v-bind="attrs" v-on="on">
          <v-icon small class="mr-2">mdi-table-column-plus-after</v-icon>
          {{$t('classification.fields_of_interest')}}
        </v-btn>
      </div>
    </template>

    <v-card>
      <v-card-title>
        <v-btn outlined x-small fab color="#4e80ae">
          <v-icon x-small>mdi-table-column-plus-after</v-icon>
        </v-btn>
        <h6 class="ml-2">{{$t('classification.fields_of_interest')}}</h6>
      </v-card-title>

      <v-card-text style="height: 90%">
        <v-data-table
          :headers="headers"
          :items="temp_columns.filter((t) =>( (t.parent!==0) && (t.type!=='out') || ((t.parent==0)&& (t.type!='out'))))
          "
          :items-per-page="5"
          group-by="parent"
          show-group-by
          class="elevation-1"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:group.header="{ group, headers, toggle, isOpen }">
            <td :colspan="headers.length">
              <v-btn @click="toggle" x-small icon :ref="group">
                <v-icon v-if="isOpen">mdi-plus</v-icon>
                <v-icon v-else>mdi-minus</v-icon>
              </v-btn>
              <span
                class="mx-5 font-weight-bold text-capitalize"
                v-if="group != 0"
              >{{ $t('proto.' + temp_columns.filter(x=>x.id== group)[0].title ) }}</span>
              <span class="mx-5 font-weight-bold text-capitalize" v-else>Other fields</span>
            </td>
          </template>
          <template v-slot:item.title="{ item }">
            <span class="text-capitalize" v-if="item.active">
              <b>{{ $t('proto.' +item.title) }}</b>
            </span>
            <span class="text-capitalize" v-else>{{ $t('proto.' +item.title) }}</span>
          </template>
          <!-- <template v-slot:item.parent="{ item }">
            <span class="text-capitalize">{{ $t('proto.' + temp_columns.filter(x=>x.id== item.parent)[0].title )}}</span>
          </template>-->
          <template v-slot:item.active="{ item }">
            <v-checkbox :disabled="item.type=='out'" class="messages-off" v-model="item.active"></v-checkbox>
          </template>

          <template v-slot:item.unit="{ item }">
            <span v-if="item.oc_specs.length > 0">
                {{item.oc_specs[0].range.unit}}
              </span>
          </template>

          <template v-slot:item.fixed="{ item }">
            <v-select
              class="messages-off"
              style="font-size: 12px"
              dense
              no-gutter
              outlined
              item-value="value"
              item-text="text"
              :items="pinned_values"
              v-model="item.fixed"
            ></v-select>
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-actions>
        <v-btn text @click="cancel">{{$t('cancel')}}</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" class="pa-3" @click="save">{{$t('apply')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { cloneDeep } from "lodash";

export default {
  name: "dialogFields",
  data: () => ({
    fields_dialog: false,
    temp_columns: [],
    product_id: 0
  }),
  props: {
    columns: {
      type: Array
    }
  },
  computed: {
    pinned_values() {
      return [
        { value: "left", text: this.$t("left") },
        { value: "none", text: this.$t("none") },
        { value: "right", text: this.$t("right") }
      ];
    },
    headers() {
      return [
        {
          text: this.$t("classification.active"),
          align: "start",
          sortable: false,
          value: "active",
          groupable: false
        },
        {
          text: this.$t("classification.field"),
          value: "title",
          groupable: false
        },
        { text: this.$t("classification.category"), value: "parent" },
        {
          text: this.$t("classification.unit"),
          value: "unit",
          groupable: false
        },
        {
          text: this.$t("classification.pinned"),
          value: "fixed",
          groupable: false
        }
      ];
    }
  },

  watch: {
    columns(val) {
      this.temp_columns = cloneDeep(this.columns);
    }
  },

  mounted() {
    this.mount();
  },

  methods: {
    mount() {
      this.product_id = this.$store.getters.productParentId;
      this.temp_columns = cloneDeep(this.columns);
    },
    cancel() {
      this.$emit("columns", this.columns);
      this.fields_dialog = false;
    },
    save() {
      let pinned_values = this.pinned_values.map(x => x.value);
      this.temp_columns.sort(function(a, b) {
        var textA = pinned_values.indexOf(a.fixed);
        var textB = pinned_values.indexOf(b.fixed);
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
      this.$emit("columns", cloneDeep(this.temp_columns));
      this.fields_dialog = false;
    }
  }
};
</script>

<style>
.v-input--selection-controls {
  margin-top: 0px !important;
  padding-top: 0px !important;
}
.messages-off .v-text-field__details {
  display: none !important;
}
.messages-off .v-messages {
  display: none !important;
}
.v-input__slot {
  align-items: center;
  justify-content: center;
  display: flex;
  align-items: center;
}
</style>
