<template>
  <v-card
    v-if="selected_column.type != 'color' && selected_column.type != 'colorsurface'"
    class="pa-3 pb-5"
  >
    <v-list-item three-line>
      <v-list-item-content>
        <div class="text-overline mb-1">{{ selected_value.name }}</div>
        <v-list-item-title
          class="text-capitalize text-h6 mb-1"
        >{{$t('proto.'+ selected_column.title.toLowerCase() )}}</v-list-item-title>

        <v-list-item-subtitle>
          <small>( {{selected_column.min}} to {{selected_column.max}})</small>
          {{ selected_column.unit }}
        </v-list-item-subtitle>
      </v-list-item-content>

      <v-btn
        v-if="selected_column.type == 'range'"
        :label="$t('classification.any')"
        outlined
        rounded
        dense
        @click="to_any()"
      > Any</v-btn>
    </v-list-item>

    <div class="ma-5">
      <v-text-field
        v-if="selected_column.type == 'string'"
        label="Solo"
        placeholder="Class name"
        v-model="selected_value.name"
        solo
      ></v-text-field>

      <v-range-slider
        v-if="
            (selected_column.type == 'range') &&
            selected_value[selected_column.field] != [0, selected_column.max]
          "
        v-model="selected_value[selected_column.field]"
        :min="selected_column.min"
        :max="selected_column.max"
        step="0.1"
        hide-details
        class="align-center"
      >
        <template v-slot:prepend>
          <v-text-field
            :value="selected_value[selected_column.field][0]"
            :min="selected_column.min"
            :max="selected_column.max"
            class="mt-0 pt-0"
            hide-details
            single-line
            type="number"
            step="0.1"
            style="width: 60px"
            @change="$set(selected_value[selected_column.field], 0, $event)"
          ></v-text-field>
        </template>

        <template v-slot:append>
          <v-text-field
            :value="selected_value[selected_column.field][1]"
            :min="selected_column.min"
            :max="selected_column.max"
            class="mt-0 pt-0"
            hide-details
            single-line
            type="number"
            step="0.1"
            style="width: 60px"
            @change="$set(selected_value[selected_column.field], 1, $event)"
          ></v-text-field>
        </template>
      </v-range-slider>
    </div>
  </v-card>
</template>


<script>
export default {
  name: "sliderRange",

  props: {
    selected_column: {
      type: Object
    },
    selected_value: {
      type: Object
    }
  },

  data: () => ({}),
  methods: {
    to_any(){
        this.$set(this.selected_value[this.selected_column.field], 0, this.selected_column.min);
        this.$set(this.selected_value[this.selected_column.field], 1, this.selected_column.max);
        
    }
  }
};
</script>


