





















import Vue from "vue";
import { mapState, mapActions } from "vuex";

import { State ,MachineSettings } from "@/store/model";
import { machine_component_categories_meta} from "gs-proto/js/gs/proto/categories_data_meta";
import { MachineComponent } from "gs-proto/js/gs/proto/machine_data_pb";

import MachineComponentSlider from "./sliders/MachineComponentSlider.vue";

export default Vue.extend({
  name: "settings",
  data: () => ({
    machine_component_categories_meta,
    machine_components_icons:{ 
        'machine-speed':'mdi-speedometer',
        'brush-speed':'mdi-decagram',
        'singulator-camera':'mdi-camera-iris',
        'singulator-middle':'mdi-elevator-down',
        'singulator-infeed':'mdi-elevator-up'
        },
    default_icon : 'mdi-cog-outline',
  }),
  components: {
    MachineComponentSlider
  },
  computed: {
    ...mapState({
      machineComponents:  state => (state as State).machine?.componentsList as MachineComponent.AsObject[]
    }),
  },
  methods:{
    get_icon(title){
      if (this.machine_components_icons[title]){
        return this.machine_components_icons[title];
      }
      return this.default_icon;
    },
    restart(){
      for (const [key, value] of Object.entries( this.$refs)) {
        if (value){
            value[0].restart();   
        }
      }
    }
  }

});
