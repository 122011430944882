























































import Vue from "vue";
import { mapState, mapActions } from "vuex";
import { cloneDeep } from "lodash";

import {
  LanesetConfigChangeRequest,
  CupObject
} from "gs-proto/js/gs/proto/ui_data_pb";
import { ObjectProfileMetaType } from "gs-proto/js/gs/proto/categories_data_meta";
import { ObjectProfile } from "gs-proto/js/gs/proto/object_data_pb";

import { VisionConfiguration } from "gs-proto/js/gs/proto/configs_data_pb";
import { State } from "@/store/model";

export default {
  data() {
    return {
      dialog: false,
      modes_map: { 0: "PRODUCTION", 10: "STAGING", 20: "DEBUGGING" } //TODO
    };
  },
  props: {
    dark: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      machine: state => (state as State).machine
    }),

    modes() {
      return [
        { text: "PRODUCTION", value: 0 },
        { text: "STAGING", value: 10 },
        { text: "DEBUGGING", value: 20 }
      ]; //TODO
    }
  },

  methods: {
    ...mapActions(["change_laneset_config"]),

    change_lane(m, value) {
      let laneset_config: LanesetConfigChangeRequest.AsObject = {
        lanesetId: m.id,
        mode: value
      };
      (this as any).change_laneset_config(laneset_config);
    }
  }
};
