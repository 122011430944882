
import paper from "paper";

const toolEraser = (scope) => {
    const tool = new scope.Tool()
    tool.name = 'eraser'

    tool.onMouseDown = function (event) {
        let all_paths = this.scope.project.getItems({ class: this.scope.Path })
        all_paths.forEach(p=>{
            if (p.contains(event.point)) {
                p.selected = true
                p.remove()
            }else{
                p.selected = false
            }
        })
    }

    tool.setWH = function (scope, w, h) {
        this.scope = scope;
        this.w = w;
        this.h = h;
    }
    return tool
}

export default toolEraser;