import { Range, Filter, Polygon, Point, Clazz, Criterion, Program, ObjectCategoryProgram, DroppingRelation, ExitGroup, ClassPercentage, AutoStop, ColorRange, ColorSurfaceRange } from "gs-proto/js/gs/proto/program_data_pb";
import { Field } from "gs-proto/js/gs/proto/categories_data_pb";
import { fields_meta, FieldMetaType } from "gs-proto/js/gs/proto/categories_data_meta";

export const MappingFunction = {
    filterToProto(filter: Filter.AsObject) {
        let new_filter = new Filter()
        new_filter.setId(filter.id)
        new_filter.setField(filter.field)
        new_filter.setMaxSaturation(filter.maxSaturation)
        new_filter.setMinSaturation(filter.minSaturation)
        let _polygon_list: Polygon[] = []
        filter.polygonsList.forEach(pol => {
            let new_polygon = new Polygon()
            let _points_list: Point[] = []
            pol.pointsList.forEach(p => {
                let new_point = new Point()
                new_point.setX(p.x)
                new_point.setY(p.y)
                _points_list.push(new_point)
            })
            new_polygon.setPointsList(_points_list)
            _polygon_list.push(new_polygon)
        })
        new_filter.setPolygonsList(_polygon_list)
        return new_filter
    },


    programToProto(program: Program.AsObject) {
 
        let _program = new Program();
        _program.setId(program.id);
        _program.setTitle(program.title);

        let objectCategoryProgramsList: ObjectCategoryProgram[] = []
        program.objectCategoryProgramsList.forEach(function (objectCategoryProgram, i) {
            let _objectCategoryProgram = new ObjectCategoryProgram()
            _objectCategoryProgram.setObjectCategory(objectCategoryProgram.objectCategory)

            let _filter_list: Filter[] = []
            objectCategoryProgram.filtersList.forEach(filter => {
                let new_filter = new Filter()
                new_filter.setId(filter.id)
                new_filter.setField(filter.field)
                new_filter.setMaxSaturation(filter.maxSaturation)
                new_filter.setMinSaturation(filter.minSaturation)
                let _polygon_list: Polygon[] = []
                filter.polygonsList.forEach(pol => {
                    let new_polygon = new Polygon()
                    let _points_list: Point[] = []
                    pol.pointsList.forEach(p => {
                        let new_point = new Point()
                        new_point.setX(p.x)
                        new_point.setY(p.y)
                        _points_list.push(new_point)
                    })
                    new_polygon.setPointsList(_points_list)
                    _polygon_list.push(new_polygon)
                })
                new_filter.setPolygonsList(_polygon_list)
                _filter_list.push(new_filter)
            })
            _objectCategoryProgram.setFiltersList(_filter_list)

            let _class_list: Clazz[] = []
            objectCategoryProgram.classesList.forEach(class_list => {

                let new_clazz = new Clazz()
                new_clazz.setId(class_list.id)
                new_clazz.setName(class_list.name)
                let _criteria: Criterion[] = []

                class_list.criteraList.forEach(criteria => {
                    let criteria_range: any = criteria.range //TODO
                    let field_type = fields_meta.get(criteria.field) ?.type || 0;
                    if (field_type == 1) {
                        let new_range = new Range()
                        if (criteria_range.length == 2) {
                            new_range.setMinValue(criteria_range[0])
                            new_range.setMaxValue(criteria_range[1])
                        } else {
                            new_range.setMinValue(criteria_range.minValue)
                            new_range.setMaxValue(criteria_range.maxValue)
                        }
                        let new_criteria = new Criterion()
                        new_criteria.setField(criteria.field)
                        new_criteria.setRange(new_range)

                        _criteria.push(new_criteria)
                    }
                    if (field_type == 2) {
                        //bool
                        let new_criteria = new Criterion()
                        new_criteria.setField(criteria.field)
                        new_criteria.setBool(criteria.bool)
                        _criteria.push(new_criteria)
                    }
                    if (field_type == 3) {
                        // color
                        let new_criteria = new Criterion();
                        new_criteria.setField(criteria.field);
                        let criteria_color_range = criteria.colorRange;

                        if (criteria_color_range != undefined) {
                            let colorRange = new ColorRange();
                            colorRange.setMinHue(criteria_color_range['minHue'])
                            colorRange.setMaxHue(criteria_color_range['maxHue'])
                            new_criteria.setColorRange(colorRange);
                        }
                        _criteria.push(new_criteria);
                    }

                    if (field_type == 4) {
                        // color surface
                        let new_criteria = new Criterion();
                        new_criteria.setField(criteria.field);
                        let criteria_color_range = criteria.colorSurfaceRange;
                        console.log('adding criteria for blush', criteria.field, criteria_color_range)
                        if (criteria_color_range != undefined) {
                            let colorRange = new ColorSurfaceRange();
                            colorRange.setMinSurface(criteria_color_range['minSurface'])
                            colorRange.setMaxSurface(criteria_color_range['maxSurface'])

                            new_criteria.setColorSurfaceRange(colorRange);
                        }
                        _criteria.push(new_criteria);
                    }

                })
                new_clazz.setCriteraList(_criteria)
                _class_list.push(new_clazz)
            })

            _objectCategoryProgram.setClassesList(_class_list)

            _objectCategoryProgram.setFieldsList(objectCategoryProgram.fieldsList)
            _objectCategoryProgram.setLanesetIdsList(objectCategoryProgram.lanesetIdsList)
            objectCategoryProgramsList.push(_objectCategoryProgram)
        });
        _program.setObjectCategoryProgramsList(objectCategoryProgramsList)

        let droppingRelationsList: DroppingRelation[] = []
        program.droppingRelationsList.forEach(droppingRelation => {
            let _droppingRelation = new DroppingRelation()

            if (droppingRelation.exitId != 0) { _droppingRelation.setExitId(droppingRelation.exitId) }
            if (droppingRelation.groupId != 0) { _droppingRelation.setGroupId(droppingRelation.groupId) }
            _droppingRelation.setId(droppingRelation.id)
            _droppingRelation.setName(droppingRelation.name)

            _droppingRelation.setPackageId(droppingRelation.packageId)

            let _classPercentageList: ClassPercentage[] = []
            droppingRelation.classPercentageList.forEach(c => {
                let _classPercentage = new ClassPercentage
                _classPercentage.setClassId(c.classId)
                _classPercentage.setPercentage(c.percentage)
                _classPercentageList.push(_classPercentage)
            })
            _droppingRelation.setClassPercentageList(_classPercentageList)
            _droppingRelation.setMixOption(droppingRelation.mixOption)

            droppingRelationsList.push(_droppingRelation)
        })
        _program.setDroppingRelationsList(droppingRelationsList);


        let exitGroupsList: ExitGroup[] = []
        program.exitGroupsList.forEach(exit_group => {
            let _exit_group = new ExitGroup()
            _exit_group.setExitIdList(exit_group.exitIdList)
            _exit_group.setFillOption(exit_group.fillOption)
            _exit_group.setId(exit_group.id)
            _exit_group.setName(exit_group.name)
            exitGroupsList.push(_exit_group)
        })
        _program.setExitGroupsList(exitGroupsList)

        return _program

    }
}
