import "vuetify/dist/vuetify.min.css";
import Vue from "vue";
import Vuetify from "vuetify";
import VueI18n from "vue-i18n";
import VueImg from 'v-img';

import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'


import App from "./App.vue";
import router from "./router";
import store from "./store";

import { languages } from "../src/locales/index.js";
import { defaultLocale } from "../src/locales/index.js";

// Sync router with store
import { sync } from "vuex-router-sync";

import "vue-easytable/libs/theme-default/index.css"; // import style
import {
  VeTable,
  VePagination,
  VeIcon,
  VeLoading,
  VeLocale,
} from "vue-easytable"; // import library

Vue.use(VeTable);
Vue.use(VePagination);
Vue.use(VeIcon);
Vue.use(VeLoading);
Vue.use(VueImg);


Vue.use(VueViewer)


Vue.prototype.$veLoading = VeLoading;
Vue.prototype.$veLocale = VeLocale;

Vue.config.productionTip = false;

Vue.use(VueI18n);

const messages = Object.assign(languages);
const i18n = new VueI18n({
  locale: defaultLocale,
  fallbackLocale: "en",
  messages,
});

Vue.use(Vuetify);

sync(store, router);

new Vue({
  vuetify: new Vuetify(
    {
      theme: {
        themes: {
          light: {
            primary: '#465274',   
            secondary: '#222221',   
            success:'#30c8ae',
            white:'#ffffff',
            bar:"#dfe4ea",
            barContrast:"#465274",
            lighted:"#e77a02",
            disabled:"#aaadb0"

          },
          dark: {
            primary: '#465274',
            secondary: '#d3d3d3',   
            success:'#30c8ae',
            white:'#ffffff',
            bar:"#dfe4ea",
            barContrast:"#465274", 
            lighted:"#e77a02",
            disabled:"#aaadb0"
          },
        },
      },
      icons: {
        iconfont: 'mdi' //'fa', //'mdiSvg',  || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
      }
    }
  ),
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
