






































import Vue from "vue";
import Settings from "./Settings.vue";

export default Vue.extend({
  name: "settingsDialog",
  components: { Settings },
  data: () => ({
    dialog: false
  }),
  props: {
    as_card: {
      type: Boolean,
      default: false
    }
  },
  watch:{
    dialog(v){
      let settings: any = this.$refs.settings as Vue;
      if (settings) { settings.restart();}
    }
  }

});
