





























































































import Vue from "vue";
import paper from "paper";
import { mapState, mapActions } from "vuex";
import { cloneDeep } from "lodash";

import {
  LanesetConfigChangeRequest,
  CupObject
} from "gs-proto/js/gs/proto/ui_data_pb";
import {
  fields_meta,
  ObjectProfileMetaType
} from "gs-proto/js/gs/proto/categories_data_meta";
import { ObjectProfile } from "gs-proto/js/gs/proto/object_data_pb";

import { VisionConfiguration } from "gs-proto/js/gs/proto/configs_data_pb";
import { State } from "@/store/model";

import LaneDialog from "@/components/results/LaneDialog.vue";

export default {
  components: {
    LaneDialog
  },

  data() {
    return {
      loading: false,
      current_h: undefined,
      current_src: undefined,
      idx_selected: 0,
      canvas_id: undefined,
      fields_meta,
      selected: []
    };
  },
  props: {
    scope: {
      type: Object
    },
    avg_color_range: {
      type: Array
    },
    s_range: {
      type: Array
    },
    field_id: {
      type: Number
    },
    canvas_size: {
      type: Number
    },
    canvas_size_width: {
      type: Number
    },
    id: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...mapState({
      cup_objects: state => (state as State).cup_objects as CupObject.AsObject[]
    }),
    color_field() {
      return (this as any).fields_meta.get((this as any).field_id).type == 3
        ? "color"
        : "colorSurface";
    }
  },
  mounted() {
    (this as any).clear_canvas();
    (this as any).generate_src();
  },

  methods: {
    change_canvas_id(i) {
      (this as any).canvas_id = i;
      (this as any).clear_canvas();
      (this as any).generate_src();
    },
    generate_src() {
      if ((this as any).canvas_id != undefined) {
        return `data:${
          (this as any).cup_objects[(this as any).idx_selected].objectProfile
            .debugTablesList[0].cellsList[(this as any).canvas_id].mimetype
        };base64,${
          (this as any).cup_objects[(this as any).idx_selected].objectProfile
            .debugTablesList[0].cellsList[(this as any).canvas_id].image
        }`;
      }
    },

    clear_canvas() {
      (this as any).current_src = (this as any).generate_src();
      let h_size = 300;
      let w_size = 400;
      let canvas = <HTMLCanvasElement>(
        document.getElementById(
          "product_canvas_" +
            (this as any).field_id +
            "_" +
            String((this as any).id)
        )
      );
      let ctx = canvas.getContext("2d");
      if (ctx != null) {
        ctx.clearRect(0, 0, w_size, h_size);
        let src = (this as any).generate_src();
        canvas.width = w_size;
        canvas.height = h_size;
        var img = new Image();
        img.onload = function() {
          if (ctx != null) {
            ctx.drawImage(
              img,
              0,
              0,
              img.width,
              img.height,
              0,
              0,
              w_size,
              h_size
            );
          }
        };
        img.src = src;
      }

      (this as any).current_h = undefined;

      // ctx.clear();
    },
    is_in_paths(h, l, min_h, max_h) {
      if (h < min_h) {
        return false;
      }
      if (h > max_h) {
        return false;
      }
      let event_point = new (this as any).scope.Point(h, l);
      let hitResult = (this as any).scope.project.hitTest(event_point);
      if (
        hitResult &&
        (hitResult.item.type == "rectangle" || hitResult.item.type == "polygon")
      ) {
        return true;
      }
      return false;
    },
    change_load() {
      (this as any).loading = false;
    },

    colors(cup, img_id) {
      let src = (this as any).current_src;
      let s_range = (this as any).s_range;

      (this as any).loading = true;
      let h_size = 300;
      let w_size = 400;
      let min_h =
        ((this as any).avg_color_range[0] / 100) *
        (this as any).canvas_size_width;
      let max_h =
        ((this as any).avg_color_range[1] / 100) *
        (this as any).canvas_size_width;

      var img = new Image();

      let canvas = <HTMLCanvasElement>(
        document.getElementById(
          "product_canvas_" +
            (this as any).field_id +
            "_" +
            String((this as any).id)
        )
      );
      let ctx = canvas.getContext("2d");
      canvas.width = w_size;
      canvas.height = h_size;
      var is_in_paths = (this as any).is_in_paths;
      var change_load = (this as any).change_load;
      img.onload = function() {
        if (ctx) {
          ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, w_size, h_size);
          var imageData = ctx.getImageData(0, 0, w_size, h_size);
          var s = editPixels(
            imageData.data,
            is_in_paths,
            min_h,
            max_h,
            s_range
          );
          drawEditedImage(imageData);
          change_load();
        }
      };
      img.src = src;

      function editPixels(imgData, is_in_paths, min_h, max_h, s_range) {
        function rgbToHsl(r, g, b) {
          (r /= 255), (g /= 255), (b /= 255);
          var max = Math.max(r, g, b),
            min = Math.min(r, g, b);
          var h,
            s,
            l = (max + min) / 2;

          if (max == min) {
            h = s = 0; // achromatic
          } else {
            var d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
            switch (max) {
              case r:
                h = (g - b) / d + (g < b ? 6 : 0);
                break;
              case g:
                h = (b - r) / d + 2;
                break;
              case b:
                h = (r - g) / d + 4;
                break;
            }
            h /= 6;
          }

          return { h: h, s: s, l: l };
        }

        let canvas_size = 400; //TODO
        let canvas_size_width = 580; //todo

        for (var i = 0; i < imgData.length; i += 4) {
          let hsl = rgbToHsl(imgData[i], imgData[i + 1], imgData[i + 2]);
          let h = hsl["h"];

          if (h < 0.5) {
            h += 0.5;
          } else {
            h -= 0.5;
          }
          h = h * canvas_size_width;

          let l = hsl["l"] * canvas_size;

          if (hsl["s"] * 100 < s_range[0] || hsl["s"] * 100 > s_range[1]) {
            imgData[i + 3] = 60;
          } else {
            if (!is_in_paths(h, l, min_h, max_h)) {
              imgData[i + 3] = 60;
            }
          }
        }
      }

      function drawEditedImage(newData) {
        let canvasEdited = document.getElementById(img_id);
        let ctxEdited = canvas.getContext("2d");
        if (ctxEdited) {
          ctxEdited.putImageData(newData, 0, 0);
        }
      }
    },
    emit() {
      (this as any).$emit("range_computed", (this as any).range_computed);
      (this as any).selected = [];
    },
    add_to_selected(c) {
      (this as any).selected.push(c);
    },
    hsv_to_hsl(h, s, v) {
      // both hsv and hsl values are in [0, 1]
      var l = ((2 - s) * v) / 2;

      if (l != 0) {
        if (l == 1) {
          s = 0;
        } else if (l < 0.5) {
          s = (s * v) / (l * 2);
        } else {
          s = (s * v) / (2 - l * 2);
        }
      }

      return [h, s, l];
    },

    renderOneColor(color) {
      let [h, s, l] = (this as any).hsv_to_hsl(
        (color.hue * 2) / 360,
        color.saturation / 255,
        color.value / 255
      );
      let style =
        "background:  hsl(" + h * 360 + "," + s * 100 + "%, " + l * 100 + "%)";
      return style;
    },

    HueSVtoHueSL(color) {
      let [h, s, l] = (this as any).hsv_to_hsl(
        (color.hue * 2) / 360,
        color.saturation / 255,
        color.value / 255
      );
      h = h * 360;
      return h <= 180 ? h.toFixed(1) : (360 - h) * -1;
    },
    SaturationVtoSaturationL(color) {
      let [h, s, l] = (this as any).hsv_to_hsl(
        (color.hue * 2) / 360,
        color.saturation / 255,
        color.value / 255
      );
      return (s * 100).toFixed(1);
    },

    ValuetoLigth(color) {
      let [h, s, l] = (this as any).hsv_to_hsl(
        (color.hue * 2) / 360,
        color.saturation / 255,
        color.value / 255
      );
      return (l * 100).toFixed(1);
    },

    renderColor(colorRange) {
      let hue = [colorRange.minHue, colorRange.maxHue];
      let s = (colorRange.minSaturation + colorRange.maxSaturation) / 2;
      let v = (colorRange.minValue + colorRange.maxValue) / 2;
      let colors = [] as any;
      for (let i = hue[1]; i > hue[0]; i -= 5) {
        colors.push("hsl(" + i + "," + s + "%, " + v + "%)");
      }
      let style =
        "background: linear-gradient(to left, " + colors.join() + ");";
      return style;
    }
  }
};
