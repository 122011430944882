















































import Vue from "vue";
import { mapState, mapActions } from "vuex";
import { cloneDeep } from "lodash";
import draggable from "vuedraggable";

import { Exit, ExitState } from "gs-proto/js/gs/proto/machine_data_pb";
import {
  AutoStop,
  Clazz,
  ClassPercentage,
  DroppingRelation,
  ExitGroup
} from "gs-proto/js/gs/proto/program_data_pb";
import { State } from "@/store/model";

import MixEdit from "../edit_dialogs/MixEdit.vue";
import MixComponent from "./Mix.vue";
import { Colors } from "./mix/colors";

export default Vue.extend({
  name: "exitDinamicDragable",
  components: {
    MixComponent,
    draggable,
    MixEdit,
  },
  props: {
    exit: { type: Object },
    is_group: { type: Boolean },
    mixes: { type: Array },
    cols: { type: Number },
  },
  data() {
    return {
      dialog: false,
      tmp_list: [],
      tmp_list2: [],
      timeout: 5000,
      alert: false,
      msg: "" as String
    };
  },

  computed: {
    ...mapState({
      classList:  state => (state as State).program?.objectCategoryProgramsList[0].classesList as Clazz.AsObject[],
      droppingRelationsList: state => (state as State).program?.droppingRelationsList as DroppingRelation.AsObject[],
      tmp_droppingRelationsList: state => (state as State).tmp_droppingRelationsList || [],
      tmp_current_groups: state => (state as State).tmp_current_groups || [],

    }),
    
     mix_options() {
      return [
        { value: 0, text: (this as any).$t("distribution.mix_exit_flow") },
        { value: 1, text: (this as any).$t("distribution.mix_incoming_flow") }
      ];

    },
  },

  methods: {
    ...mapActions(["store_tmp_droppingRelationsList", "store_tmp_groups"]),
    
    correct_mixes(event, drop_exit) {
      if (event == 0) {
        let n_classes = drop_exit.classPercentageList.length;
        drop_exit.classPercentageList.forEach(
          c => (c.percentage = 100 / n_classes)
        );
      } else {
        drop_exit.classPercentageList.forEach(c => (c.percentage = -1));
      }
    },

    update_remaining_exits(drop_exit, event) {
      let tmp_droppingRelationsList = cloneDeep(
        (this as any).tmp_droppingRelationsList
      );
      let total_percent = 0;
      tmp_droppingRelationsList
        .filter(d => d.id != drop_exit.id)
        .forEach(dr => {
          dr.classPercentageList
            .filter(x => x.classId == 0)
            .forEach(x => {
              total_percent += x.percentage;
            });
        });
      if (total_percent + event <= 100) {
        drop_exit.classPercentageList[0].percentage = event;
      } else {
        drop_exit.classPercentageList[0].percentage = 100 - total_percent;
      }
      // this.$emit("tmp_droppingRelationsList", tmp_droppingRelationsList);
      (this as any).store_tmp_droppingRelationsList(tmp_droppingRelationsList)
    },

    update_drop_exit(drop_exit, event) {
      let _tmp_droppingRelationsList = (this as any).tmp_droppingRelationsList.filter(
        x => x.id != drop_exit.id
      );
      _tmp_droppingRelationsList.push(event);
      // this.$emit("tmp_droppingRelationsList", tmp_droppingRelationsList);
      (this as any).store_tmp_droppingRelationsList(_tmp_droppingRelationsList)
    },

    drop_filter(group: ExitGroup.AsObject): DroppingRelation.AsObject {
      return (this as any).tmp_droppingRelationsList.filter(
        x => x.groupId == group.id
      );
    },

    drop_filter_exit(exit: Exit.AsObject): DroppingRelation.AsObject {
      if ((this as any).is_group) {
        return (this as any).drop_filter(exit);
      }
      return (this as any).tmp_droppingRelationsList.filter(
        x => x.exitId == exit.id
      );
    },

    clear(drop_group: DroppingRelation.AsObject): void {
  
      let n_products = (this as any).tmp_droppingRelationsList.filter(x=>x.groupId==drop_group.groupId).length ;
      let _tmp_current_groups = cloneDeep((this as any).tmp_current_groups);
      let dg_group = _tmp_current_groups.filter(x=>x.id==drop_group.groupId)[0];

      if (( n_products - 1 == 1) && dg_group.fillOption > 1 ){
        dg_group.fillOption = 0; // not allow dynamic fillOption if n_products == 1
        (this as any).store_tmp_groups(_tmp_current_groups);
      }
  
      (this as any).store_tmp_droppingRelationsList((this as any).tmp_droppingRelationsList.filter(
          x => x.id != drop_group.id
        ));
    },

    generate_new_id(dr_list) {
      let dr_ids = dr_list.map(x => x.id);
      let date = new Date();
      let components = [
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
        date.getMilliseconds()
      ];
      let new_id = parseInt(components.join(""));
      while (dr_ids.includes(new_id)) {
        new_id += 1;
      }
      return new_id;
    },

    classPercentageList_one_class(class_id): ClassPercentage.AsObject[] {
      return [{ classId: class_id, percentage: -1 }];
    },

    classPercentageList_remaining(new_id, exit) {
      let cnt = 2;
      (this as any).tmp_droppingRelationsList.forEach(element => {
        cnt += element.classPercentageList.filter(x => x.classId == 0).length;
      });

      let remaining = 100 / cnt;

      let tmp_droppingRelationsList = cloneDeep(
        (this as any).tmp_droppingRelationsList
      );

      tmp_droppingRelationsList.forEach(element => {
        element.classPercentageList
          .filter(x => x.classId == 0)
          .forEach(c => {
            c.percentage = remaining;
          });
      });

      let new_droping_relation: DroppingRelation.AsObject = {
        id: new_id,
        name: String("drop_" + new_id),
        classPercentageList: [{ classId: 0, percentage: remaining }],
        mixOption: 0,
        exitId: exit.id,
        groupId: 0,
        packageId: 0 
      };
      tmp_droppingRelationsList.push(new_droping_relation);

      // (this as any).$emit(
      //   "tmp_droppingRelationsList",
      //   tmp_droppingRelationsList
      // );
       (this as any).store_tmp_droppingRelationsList(tmp_droppingRelationsList)
      
    },

     covered_percentage(elem_id){
      let covered_percentage = 0;
      (this as any).tmp_droppingRelationsList
        .filter(x => x.mixOption == 1)
        .forEach(dr => {
          dr.classPercentageList
            .filter(c => c.classId == elem_id)
            .forEach(cl => {
              if (cl.percentage == -1){
                covered_percentage += 100
              }else
            { covered_percentage += cl.percentage;}
            });
        });
        return covered_percentage
    },


    new_drop(event: any, exit: Exit.AsObject, is_group: boolean) {
      let tmp_droppingRelationsList = cloneDeep(
        (this as any).tmp_droppingRelationsList
      );
      let new_id = (this as any).generate_new_id(tmp_droppingRelationsList);
      let keyOut = "groupId";
      let exit_drop_empty =
        tmp_droppingRelationsList.filter(x => x[keyOut] == exit.id).length == 0;

      if (event.added) {
        let elem = event.added.element;

         // CASE -1 : Unnasigned CLASS
        if (elem.id == -1) {     
          return 0;
        }
        
        // CASE 0 : DEFAULT CLASS
        if (elem.id == 0) {
          if (!is_group && exit_drop_empty) {
            (this as any).classPercentageList_remaining(new_id, exit);
          }
          return 0;
        }

          let covered_percentage = (this as any).covered_percentage(elem.id);
          if (!(covered_percentage < 100)) {
            (this as any).alert = true;
            (this as any).msg = "100% of this class is covered already";
            return 0;
          }

        let new_droping_relation: DroppingRelation.AsObject = {
          id: new_id,
          name: String("drop_" + new_id),
          classPercentageList: (this as any).classPercentageList_one_class(
            elem.id
          ),
          exitId: 0,
          groupId: 0,
          mixOption: 1,
          packageId:0
        };
        new_droping_relation[keyOut] = exit.id;
        tmp_droppingRelationsList.push(new_droping_relation);
        
        let n_products = this.tmp_droppingRelationsList.filter(x=>x.groupId==exit.id).length ;
        if (( n_products + 1 > 1) && exit['fillOption'] == 1 ){
            exit['fillOption'] = 0; // not allow simultaneous fillOption if n_products > 1
        }
        (this as any).store_tmp_droppingRelationsList(tmp_droppingRelationsList);
      }
    },

    log(
      event: any,
      exit: Exit.AsObject,
      is_group: boolean,
      drop_exit: DroppingRelation.AsObject
    ) {
      let tmp_droppingRelationsList = cloneDeep(
        (this as any).tmp_droppingRelationsList
      );
      let new_id = drop_exit.id;
      let keyOut = is_group ? "groupId" : "exitId";

      let exit_drop_empty = false;
      if (event.added) {
        let elem = event.added.element;

        // CASE 0 : DEFAULT CLASS
        if (elem.id == 0) {          
          return 0;// REMAINING  ALLOW??
        }

        // CASE 2: ALREADY A CLASS IN EXIT/GROUP
        let prev_droprelation = tmp_droppingRelationsList.filter(
          x => x.id == drop_exit.id
        )[0];

        // Check not duplicated
        if (
          prev_droprelation.classPercentageList
            .map(x => x.classId)
            .includes(elem.id)
        ) {
          (this as any).alert = true;
          (this as any).msg = this.$t("distribution.duplicated").toString();
          return 0;
        }
        // TODO?? If class already set up
        else {
          let new_percentage = 0;
          if (prev_droprelation.mixOption == 0) {
            new_percentage =
              100 / (prev_droprelation.classPercentageList.length + 1);
            prev_droprelation.classPercentageList.forEach(m => {
              m.percentage = new_percentage;
            });
          } else {
             let covered_percentage = (this as any).covered_percentage(elem.id);
              if (!(covered_percentage < 100)) {
                (this as any).alert = true;
                (this as any).msg = "100% of this class is covered already";
                return 0;
              }
                new_percentage = -1;
          }

          prev_droprelation.classPercentageList.push({
            classId: elem.id,
            percentage: new_percentage
          });

          // (this as any).$emit(
          //   "tmp_droppingRelationsList",
          //   tmp_droppingRelationsList
          // );
           (this as any).store_tmp_droppingRelationsList(tmp_droppingRelationsList)
        }
      }
      (this as any).tmp_list2 = [];
    }
  }
});
