










































































































































import Vue from "vue";
import paper from "paper";

import { mapState, mapActions } from "vuex";

import { Field } from "gs-proto/js/gs/proto/categories_data_pb";
import { Filter, Polygon, Point } from "gs-proto/js/gs/proto/program_data_pb";
import { State } from "@/store/model";

import {
  LanesetConfigChangeRequest,
  CupObject
} from "gs-proto/js/gs/proto/ui_data_pb";
import {
  fields_meta,
  ObjectProfileMetaType
} from "gs-proto/js/gs/proto/categories_data_meta";
import { ObjectProfile } from "gs-proto/js/gs/proto/object_data_pb";

import LastColors from "./LastColors.vue";

export default Vue.extend({
  name: "sliderColor",

  components: {
    LastColors
  },

  props: {
    selected_column: {
      type: Object
    },
    selected_value: {
      type: Object
    },
    dialog: {
      type: Boolean
    }
  },

  data: () => ({
    second_range:[0,0],
    started: false,
    filter_found:false,
    canvas_size: 400,
    canvas_size_width: 580,
    scope: undefined,
    s_range: [0, 100],
    alert_msg: "",
    loading: false,
    current_src: undefined,
    idx_selected: 0,
    paper_canvas_created: false
  }),

  computed: {
    ...mapState({
      current_program: state => (state as State).program,
      cup_objects: state =>
        (state as State).cup_objects as CupObject.AsObject[],
      tmp_current_filters: state =>
        (state as State).tmp_current_filters as Filter.AsObject[]
    })
  },

  watch: {
    second_range(v){
      this.$set(this.selected_value[this.selected_column.field], 2,v[0]);
      this.$set(this.selected_value[this.selected_column.field], 3,v[1]);
      this.update_filter();

    },
    dialog(v) {
      if (v) {
        this.idx_selected = -1;
        (this as any).draw_filter();
        (this as any).update_filter();
      } else {
        let all_paths = (this as any).scope.project.getItems({
          class: (this as any).scope.Path
        });
        if (all_paths.length > 0) {
          all_paths.forEach(p => {
            p.remove();
          });
        }
      }
    }
  },
  updated() {
    if (!this.paper_canvas_created) {
      (this as any).update_filter();
    }
  },

  mounted() {
  
    (this as any).scope = new paper.PaperScope();
    (this as any).draw_filter();

    this.second_range[0] = this.selected_value[this.selected_column.field][2]
    this.second_range[1] = this.selected_value[this.selected_column.field][3]

  },

  methods: {
    paper_canvas() {
      let paper_canvas = <HTMLCanvasElement>(
        document.getElementById(
          "paper_canvas_" +
            (this as any).current_program.id +
            "_" +
            (this as any).selected_value.id
        )
      );

      if (paper_canvas) {
        (this as any).paper_canvas_created = true;
        paper_canvas.height = (this as any).canvas_size;
        paper_canvas.width = (this as any).canvas_size_width;

        paper_canvas.style.width = (this as any).canvas_size_width+ 'px';
        paper_canvas.style.height = (this as any).canvas_size+ 'px';

        return paper_canvas;
      }
      (this as any).paper_canvas_created = false;
      return paper_canvas;
    },

    draw_filter() {
      let paper_canvas = (this as any).paper_canvas();
      (this as any).draw_rectangle();
      (this as any).scope.setup(paper_canvas);
      (this as any).load_previous_filter();
      (this as any).update_filter();
    },

    load_previous_filter() {
      // LOAD PREVIOUS FILTER
      let w = (this as any).canvas_size_width;
      let h = (this as any).canvas_size;

      let field_id = (this as any).selected_column.id;
      (this as any).filter_found = false;
      (this as any).tmp_current_filters.forEach(f => {
        if (f.getField() == field_id) {
          (this as any).filter_found = true;
          (this as any).s_range = [f.getMinSaturation(), f.getMaxSaturation()];
          f.getPolygonsList().forEach(pl => {
            let segments = [] as any;

            pl.getPointsList().forEach(p => {
              segments.push([p.getX() * w, p.getY() * h]);
            });

            let path = new (this as any).scope.Path(segments);
            path["type"] = "polygon";
            path.selectedColor = new (this as any).scope.Color("#f1f6ff");
            path.fillColor = new (this as any).scope.Color("#161817");
            path.closed = true;
            path.fillColor.alpha = 0.01;
            path.strokeColor = new (this as any).scope.Color("#161817");
            path.strokeWidth = 3;
          });
        }
      });
    },
    update_filter() {
      if (!(this as any).paper_canvas_created) {
        let paper_canvas = (this as any).paper_canvas();
        (this as any).scope.setup(paper_canvas);
        (this as any).load_previous_filter();
      }
      let p = this.paper_canvas();
      let v = (this as any).selected_value[(this as any).selected_column.field];
      let all_paths = (this as any).scope.project.getItems({
        class: (this as any).scope.Path
      });
      all_paths.forEach(p => {
        if (p["type"] == "color_range") {
          p.remove();
        }
      });

      all_paths = (this as any).scope.project.getItems({ class: paper.Path });
      var range_path = new (this as any).scope.Path.Rectangle(
        new (this as any).scope.Point((v[0] / 100) * this.canvas_size_width, 0),
        new (this as any).scope.Point(
          (v[1] / 100) * this.canvas_size_width,
          this.canvas_size
        )
      );
      range_path.strokeColor = new paper.Color("#f1f6ff");
      range_path["type"] = "color_range";


       var range_path_2 = new (this as any).scope.Path.Rectangle(
        new (this as any).scope.Point((v[2] / 100) * this.canvas_size_width, 0),
        new (this as any).scope.Point(
          (v[3] / 100) * this.canvas_size_width,
          this.canvas_size
        )
      );
      range_path_2.strokeColor = new paper.Color("#f1f6ff");
      range_path_2["type"] = "color_range";


      (this as any).alert_msg = "classification.outside_filter";
      for (let i = 0; i < all_paths.length; i++) {
        let pp = all_paths[i];
        var intersections = pp.intersect(range_path);

        if (intersections.length != 0) {
          (this as any).alert_msg = "";
          (this as any).started = true;
        }

        intersections.strokeColor = new (this as any).scope.Color("#f1f6ff");
        intersections.strokeWidth = 4;
        intersections["type"] = "color_range";
        intersections.fillColor = new (this as any).scope.Color("#f1f6ff");
        intersections.fillColor.alpha = 0;



        var intersections_2 = pp.intersect(range_path_2);

        if (intersections_2.length != 0) {
          (this as any).alert_msg = "";
          (this as any).started = true;
        }

        intersections_2.strokeColor = new (this as any).scope.Color("#f1f6ff");
        intersections_2.strokeWidth = 4;
        intersections_2["type"] = "color_range";
        intersections_2.fillColor = new (this as any).scope.Color("#f1f6ff");
        intersections_2.fillColor.alpha = 0;


      }
    },
    draw_rectangle() {
      let colorCanvas3 = <HTMLCanvasElement>(
        document.getElementById(
          "color_canvas_" +
            (this as any).current_program.id +
            "_" +
            (this as any).selected_value.id
        )
      );
      if (colorCanvas3) {
        let ColorCtx = colorCanvas3.getContext("2d"); // This create a 2D context for the canvas
        if (ColorCtx) {
          ColorCtx.beginPath();

          let gradientH = ColorCtx.createLinearGradient(
            0,
            0,
            ColorCtx.canvas.width,
            0
          );
          
          gradientH.addColorStop((1 / 6) * 0, "#00ffff");
          gradientH.addColorStop((1 / 6) * 1, "#0000ff");
          gradientH.addColorStop((1 / 6) * 2, "#ff00ff");
          gradientH.addColorStop((1 / 6) * 3, "#ff0000");
          gradientH.addColorStop((1 / 6) * 4, "#ffff00");
          gradientH.addColorStop((1 / 6) * 5, "#00ff00");
          gradientH.addColorStop((1 / 6) * 6, "#00ffff");    
            
          // gradientH.addColorStop(1, color);
          ColorCtx.fillStyle = gradientH;
          ColorCtx.fillRect(
            0,
            0,
            ColorCtx.canvas.width,
            ColorCtx.canvas.height
          );

          // Create a Vertical Gradient(white to black)
          let gradientV = ColorCtx.createLinearGradient(
            0,
            0,
            0,
            (this as any).canvas_size
          );
          gradientV.addColorStop(1, "rgba(255,255,255,0.97)");
          gradientV.addColorStop(0.75, "rgba(255,255,255, 0)");
          gradientV.addColorStop(0.5, "rgba(50,50,50, 0.05)");
          gradientV.addColorStop(0.25, "rgba(50,50,50, 0.7)");
          gradientV.addColorStop(0, "rgba(10,10,10,1)");
          ColorCtx.fillStyle = gradientV;
          ColorCtx.fillRect(
            0,
            0,
            ColorCtx.canvas.width,
            ColorCtx.canvas.height
          );
        }
      }
    }
  }
});
