// https://next.vuex.vuejs.org/guide/mutations.html

import { UIEvent } from "gs-proto/js/gs/proto/ui_data_pb";
import { State,  TemporalAlert } from "./model";

import vm from "vue";
import { Filter } from "gs-proto/js/gs/proto/program_data_pb";
import { Problem } from "gs-proto/js/gs/proto/commons_data_pb";

export default {
  clear_results(state, { }) {
    // state.cupObjectsList = []
    // localStorage.setItem('texture_transfer', state.texture_transfer)
    vm.set(state, "cup_objects", []);
  },

  set_tmp_droppingRelationsList(state: State, tmp_droppingRelationsList: any) {
    state.tmp_droppingRelationsList = tmp_droppingRelationsList;
    localStorage.setItem('tmp_droppingRelationsList', tmp_droppingRelationsList)
  },
  set_tmp_current_groups(state: State, tmp_current_groups: any) {
    state.tmp_current_groups = tmp_current_groups;
    localStorage.setItem('tmp_current_groups', tmp_current_groups)
  },
  set_tmp_machine_settings(state: State, tmp_machine_settings: any) {
    state.tmp_machine_settings = tmp_machine_settings;
    localStorage.setItem('tmp_machine_settings', tmp_machine_settings)
  },
  set_tmp_current_filters(state: State, tmp_current_filters: any) {
    state.tmp_current_filters = tmp_current_filters;
    localStorage.setItem('tmp_current_filters', tmp_current_filters)
  },

  add_alert(state: State, alert: TemporalAlert): void {
    console.log('adding alert')
    state.alerts.push(alert);
  },
  dismiss_alert(state: State, alert_id: number): void {
    state.alerts = state.alerts.filter((a) => a.id != alert_id);
  },
  connected(state: State): void {
    state.ws_connected = true;
  },
  disconnected(state: State): void {
    state.ws_connected = false; 
    vm.set(state, "program", {}); // The most likely reason for connection loss is gs-control is restarted
    delete state.program; // After reconnect the program (in case) will be loaded with the first websocket message
  },
  add_debug_problem(state, msg:string):void{
    let p = new Problem();
    p.setComponent(msg)
    p.setDescription(msg)
    p.setType(4)
    p.setInterrupt(false)
    p.setId(1000000000)
    state.problemsQueue.push(p.toObject())
  },

  apply_ui_event(state, message: UIEvent): void {
    state.ws_connected = true;
    const messageAsObject = message.toObject();

    const commons = messageAsObject.commons;
    if (commons) {
      vm.set(state, "reply_commons", commons);
      // console.log(commons.problemsList.map(x=>x.description))
      let y = state.problemsQueue.concat(commons.problemsList);
      if (y.length > 0) {
        const uniqueArray = y.filter((value, index) => {
          const _value = JSON.stringify(value);
          return index === y.findIndex(y => {
            return JSON.stringify(y) === _value;
          });
        });
        const lastX = 50;
        state.problemsQueue = uniqueArray.filter((val, index, arr) => index > arr.length - lastX - 1);
      }
    }
    const machine = messageAsObject.machine;
    if (machine) {
      vm.set(state, "machine", machine);
    }
    const program = messageAsObject.program;
    if (program) {
      vm.set(state, "program", program);
    }
    const available_programs = messageAsObject.availableProgramsList;
    if (available_programs && available_programs.length > 0) {
      vm.set(state, "available_programs", available_programs);
    }
    const cup_objects = messageAsObject.cupObjectsList;
    if (cup_objects && cup_objects.length > 0) {
      vm.set(state, "cup_objects", cup_objects);
    }

    const statistics = messageAsObject.statistics;
    if (statistics) {
      vm.set(state, "statistics", statistics);
    }

    const packages = messageAsObject.packagesList;
    if (packages) {
      if (packages.length > 0) { vm.set(state, "packages", packages); }
    }
  },
};
