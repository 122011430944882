
























































































import Vue from "vue";
import { mapState, mapActions } from "vuex";
import { cloneDeep } from "lodash";
import draggable from "vuedraggable";
import { Exit, ExitState } from "gs-proto/js/gs/proto/machine_data_pb";
import { Clazz, ExitGroup } from "gs-proto/js/gs/proto/program_data_pb";
import { DroppingRelation } from "gs-proto/js/gs/proto/program_data_pb";
import { State } from "@/store/model";
import { ExitsUtils } from "@/components/distribution/utils/exits_utils";

export default ({
  data() {
    return {
      dialog: false,
      new_weight : 0
    };
  },
  props: {
    exit_id: {
      type: Number
    },
    only_icon :{
      type:Boolean,
      default: false
    }
  },

  computed: {
    ...mapState({
      listExits:  state => (state as State).machine?.exitsList as Exit.AsObject[]
    }),
    exit(){
        let v = (this as any).listExits.filter(x=>x.id == (this as any).exit_id)[0] as Exit.AsObject;
        (this as any).new_weight= v['weight']
        return v
    }
  },

  methods:{
 ...mapActions(["change_exit_weight"]),
    cancel(){
      (this as any).new_weight =  (this as any).exit['weight'];
      (this as any).dialog = false;
    },
    toggle() {
      let _ExitChange = ExitsUtils.changeWeight( (this as any).exit, (this as any).new_weight);
      (this as any).change_exit_weight(_ExitChange);
      (this as any).dialog = false
    }
  }
});
