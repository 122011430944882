// https://next.vuex.vuejs.org/guide/actions.html
import { Problem } from "gs-proto/js/gs/proto/commons_data_pb";
import {
  ExitStateChangeRequest,
  ExitWeightChangeRequest,
  ExitAccumulateChangeRequest,
  LanesetConfigChangeRequest,
  MachineComponentChangeRequest,
  UIChangeReply,
  UIChangeRequest,
} from "gs-proto/js/gs/proto/ui_data_pb";

import { Program, Package} from "gs-proto/js/gs/proto/program_data_pb";
import { MachineComponentCategory, MachineComponentCategoryMap} from "gs-proto/js/gs/proto/categories_data_pb";

import { requestChange } from "./backend/http_post";
import { TemporalAlert, MachineSettings } from "./model";

type CommitFunction = (mutation: string, payload?: any) => void;

const _problemToAlert = function (problem: Problem.AsObject): TemporalAlert {
  return {
    id: Math.floor(Math.random() * 0x1fffffffffffff),
    text: problem.description,
    level: problem.type,
  };
};

const _replyToAlertsFunc = function (context: {
  commit: CommitFunction;
}): (reply: UIChangeReply.AsObject | void) => void {
  return function (reply: UIChangeReply.AsObject | void) {
    if (!reply) {
      return;
    }
    const r = reply as UIChangeReply.AsObject;
    if (r.commons) {
      for (const problem of r.commons.problemsList) {
        if (problem.type <= Problem.Type.WARNING) {
          const alert = _problemToAlert(problem);         
          context.commit("add_alert", alert);
          setTimeout(function () {
            context.commit("dismiss_alert", alert.id);
          }, 20000);
        }
      }
    }
    // program will be comitted, when websocket recieves the related UIEvent
  };
};

const change_active_program_id = function (
  context: { commit: CommitFunction },
  program_id: number
): void {
  const request = new UIChangeRequest();
  request.setActiveProgramId(program_id);
  requestChange(request).then(_replyToAlertsFunc(context));
};

const create_or_update_program = function (
  context: { commit: CommitFunction },
  program: Program
): void {
  const request = new UIChangeRequest();
  request.setCreateOrUpdateProgram(program) // program.id == 0 to create a new 
  requestChange(request).then(_replyToAlertsFunc(context));
};

const change_laneset_config = function (
  context: { commit: CommitFunction },
  laneset_config: LanesetConfigChangeRequest.AsObject
): void {
  const request = new UIChangeRequest();
  const laneset_config_change_request = new LanesetConfigChangeRequest();
  laneset_config_change_request.setLanesetId(laneset_config.lanesetId);
  laneset_config_change_request.setMode(laneset_config.mode);
  request.setLanesetConfig(laneset_config_change_request);
  requestChange(request).then(_replyToAlertsFunc(context));
};

const change_exit_state = function (
  context: { commit: CommitFunction },
  exit_state: ExitStateChangeRequest.AsObject
): void {
  const request = new UIChangeRequest();
  const exit_state_change_request = new ExitStateChangeRequest();
  exit_state_change_request.setExitId(exit_state.exitId);
  exit_state_change_request.setDesiredState(exit_state.desiredState);
  request.setExitState(exit_state_change_request);
  requestChange(request).then(_replyToAlertsFunc(context));
};

const delete_program = function (
  context: { commit: CommitFunction },
  id: number
): void {
  console.log('deleting program ...',id)
  const request = new UIChangeRequest();
  request.setDeleteProgramId(id)
  requestChange(request).then(_replyToAlertsFunc(context));
};

const create_or_update_package = function (
  context: { commit: CommitFunction },
  pack: Package
): void {
  const request = new UIChangeRequest();
  request.setCreateOrUpdatePackage(pack) // package.id == 0 to create a new 
  requestChange(request).then(_replyToAlertsFunc(context));
};

const delete_package = function (
  context: { commit: CommitFunction },
  id: number
): void {
  console.log('deleting package ... ',id)
  const request = new UIChangeRequest();
  request.setDeletePackageId(id);
  requestChange(request).then(_replyToAlertsFunc(context));
};


const change_exit_weight= function (
  context: { commit: CommitFunction },
  exit_weight: ExitWeightChangeRequest.AsObject
): void {
  const request =new UIChangeRequest();
  const exit_weight_change_request = new ExitWeightChangeRequest();
  exit_weight_change_request.setExitId(exit_weight.exitId);
  exit_weight_change_request.setWeight(exit_weight.weight);
  request.setExitWeight(exit_weight_change_request);
  requestChange(request).then(_replyToAlertsFunc(context));
};


const change_exit_accumulate= function (
  context: { commit: CommitFunction },
  exit_accumulate: ExitAccumulateChangeRequest.AsObject
): void {
  const request =new UIChangeRequest();
  const exit_acc_change_request = new ExitAccumulateChangeRequest();
  exit_acc_change_request.setExitId(exit_accumulate.exitId);
  exit_acc_change_request.setAccumulateType(exit_accumulate.accumulateType);
  exit_acc_change_request.setValue(exit_accumulate.value);
  request.setExitAccumulation(exit_acc_change_request);
  requestChange(request).then(_replyToAlertsFunc(context));
};

const reset_statistics= function (
  context: { commit: CommitFunction },
): void {
  const request = new UIChangeRequest();
  request.setResetStatistics(true);  
  requestChange(request).then(_replyToAlertsFunc(context));
};

const change_machine_settings_double = function (
  context: { commit: CommitFunction },
  value : any,
): void {
  const request = new UIChangeRequest();
  const machine_settings_change_request = new MachineComponentChangeRequest();
  let category = value[0] as MachineComponentCategoryMap[keyof MachineComponentCategoryMap]
  let double = value[1] as number
  machine_settings_change_request.setCategory(category)
  machine_settings_change_request.setDouble(double);
  request.setComponentValue(machine_settings_change_request);
  requestChange(request).then(_replyToAlertsFunc(context));
};

const change_machine_settings_bool = function (
  context: { commit: CommitFunction },
  value : any,
): void {
  const request = new UIChangeRequest();
  const machine_settings_change_request = new MachineComponentChangeRequest();
  let category = value[0] as MachineComponentCategoryMap[keyof MachineComponentCategoryMap]
  let double = value[1] as boolean

  machine_settings_change_request.setCategory(category)
  machine_settings_change_request.setBool(double);
  request.setComponentValue(machine_settings_change_request);
  requestChange(request).then(_replyToAlertsFunc(context));
};

const store_tmp_droppingRelationsList= function (
  context: { commit: CommitFunction },
  tmp_droppingRelationsList: any
): void {
  context.commit("set_tmp_droppingRelationsList", tmp_droppingRelationsList)
};

const store_tmp_groups= function (
  context: { commit: CommitFunction },
  tmp_current_groups: any
): void {
  context.commit("set_tmp_current_groups", tmp_current_groups)
};

const store_tmp_filters= function (
  context: { commit: CommitFunction },
  tmp_filters: any
): void {
  context.commit("set_tmp_current_filters", tmp_filters)
};

const store_tmp_machine_settings= function (
  context: { commit: CommitFunction },
  tmp_machine_settings: any
): void {
  context.commit("set_tmp_machine_settings", tmp_machine_settings)
};

const set_calibration_mode = function (
  context: { commit: CommitFunction },
): void {
  const request = new UIChangeRequest();
  request.setNewState(1); //LOADCELL_CALIBRATION MachineState = 1
  requestChange(request).then(_replyToAlertsFunc(context));
};


const start_calibration = function (
  context: { commit: CommitFunction },
): void {
  const request = new UIChangeRequest();
  request.setStartCalibration(true);
  requestChange(request).then(_replyToAlertsFunc(context));
};



export default {
  change_active_program_id,
  change_laneset_config,
  change_exit_state,
  create_or_update_program,
  delete_program,
  create_or_update_package,
  delete_package,
  change_exit_weight,
  change_exit_accumulate,
  reset_statistics,
  store_tmp_droppingRelationsList,
  store_tmp_groups,
  store_tmp_filters,
  store_tmp_machine_settings,
  change_machine_settings_double,
  change_machine_settings_bool,
  set_calibration_mode,
  start_calibration,

  clear_results({ commit }, ) {
    commit("clear_results", {});
  },

};
