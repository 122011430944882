import { mapActions } from "vuex";
import { Exit, ExitState } from "gs-proto/js/gs/proto/machine_data_pb";
import { ExitStateChangeRequest, ExitWeightChangeRequest, ExitAccumulateChangeRequest  } from "gs-proto/js/gs/proto/ui_data_pb";


const exitStateToIcon: string[] = [];
exitStateToIcon[ExitState.EXIT_DISABLED] = "mdi-lock";
exitStateToIcon[ExitState.OPEN] = "mdi-play";
exitStateToIcon[ExitState.PAUSED] = "mdi-pause";
exitStateToIcon[ExitState.BLOCKED] = "mdi-stop";
exitStateToIcon[ExitState.DEFAULT] = "mdi-lock";

const exitStateToClass: string[] = [];
exitStateToClass[ExitState.EXIT_DISABLED] = "exit-disabled";
exitStateToClass[ExitState.OPEN] = "exit-open";
exitStateToClass[ExitState.PAUSED] = "exit-paused";
exitStateToClass[ExitState.BLOCKED] = "exit-blocked";
exitStateToClass[ExitState.DEFAULT] = "exit-default";

const exitStateToColor: string[] = [];
exitStateToColor[ExitState.EXIT_DISABLED] = "gray";
exitStateToColor[ExitState.OPEN] = "#b0e684";
exitStateToColor[ExitState.PAUSED] ="#e9b568";
exitStateToColor[ExitState.BLOCKED] =  "#ee4949";
exitStateToColor[ExitState.DEFAULT] = "ee4949";


export const ExitsUtils = {

    exitStateToNameArray(){
        return exitStateToClass
    },
    exitToIcon(exit: Exit.AsObject) {
        return exitStateToIcon[exit.state];
    },
    exitToClass(exit: Exit.AsObject) {
        return exitStateToClass[exit.state];
    },
    exitToColor(exit: Exit.AsObject) {
        return exitStateToColor[exit.state];
    },

    toggle(exit: Exit.AsObject) {
        const payload: ExitStateChangeRequest.AsObject = {
            exitId: exit.id,
            desiredState: ExitState.PAUSED
        };
        if (exit.state == ExitState.OPEN) {
            return payload;
        } else if (exit.state == ExitState.PAUSED) {
            payload.desiredState = ExitState.OPEN;
            return payload;
        }
    },

    changeWeight(exit: Exit.AsObject , new_weight: number) {
        const payload: ExitWeightChangeRequest.AsObject = {
            exitId: exit.id,
            weight: new_weight
        };
        if (exit.maxWeight <= new_weight) {
            return payload;
        } 
        return payload
    },

    changeAccumulation(exit: Exit.AsObject , new_value: number, accumulateType: 0|1 ) {
        const payload: ExitAccumulateChangeRequest.AsObject = {
            exitId: exit.id,
            accumulateType: accumulateType,
            value: new_value
        };
        return payload
    }
}