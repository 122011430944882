


































































import Vue from "vue";
import { mapState, mapActions } from "vuex";

import { State } from "@/store/model";
import { cloneDeep } from "lodash";
import { Exit, ExitState } from "gs-proto/js/gs/proto/machine_data_pb";
import { ExitGroup } from "gs-proto/js/gs/proto/program_data_pb";

export default Vue.extend({
  name: "groupingAlert",
  data() {
    return {
      snackbar: false,
      exit_conserv: 0,
      group_conserv: 0
    };
  },
  props: {
    snackbar_group: {
      type: Boolean,
      default: false
    },
    exits_selected: {
      type: Array
    },
    groups_selected: {
      type: Array
    }
  },
  computed: {
    ...mapState({
      tmp_droppingRelationsList: state => (state as State).tmp_droppingRelationsList || [],
      tmp_current_groups: state => (state as State).tmp_current_groups || []
    })
  },


  watch: {
    snackbar_group(v) {
      (this as any).snackbar = v;
      (this as any).exit_conserv = (this as any).exits_selected[0];
      (this as any).group_conserv = (this as any).groups_selected[0];
    },
    snackbar(v) {
      (this as any).$emit("snackbar_group", v);
      (this as any).exit_conserv = (this as any).exits_selected[0];
      (this as any).group_conserv = (this as any).groups_selected[0];
    }
  },
  methods: {
    ...mapActions(["store_tmp_droppingRelationsList", "store_tmp_groups"]),

    generate_new_id(dr_list) {
      let dr_ids = dr_list.map(x => x.id);
      let date = new Date();
      let components = [
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
        date.getMilliseconds()
      ];
      let new_id = parseInt(components.join(""));
      while (dr_ids.includes(new_id)) {
        new_id += 1;
      }
      return new_id;
    },

    create_group() {
      let new_id = (this as any).generate_new_id(
        (this as any).tmp_current_groups
      );
      let group_name =
        this.$t("distribution.group") +
        " " +
        (this as any).exits_selected.sort().join("+");

      let dropingRelation_conserv = (this as any).tmp_droppingRelationsList.filter(
        x => x.exitId == (this as any).exit_conserv
      )[0];

      // Remove all dropingRelation of exits
      let _tmp_droppingRelationsList = (this as any).tmp_droppingRelationsList.filter(
        x => !(this as any).exits_selected.includes(x.exitId)
      );

      let new_group: ExitGroup.AsObject = {
        id: new_id,
        name: group_name,
        exitIdList: (this as any).exits_selected,
        fillOption: 0 
      };
      let _tmp_current_groups = cloneDeep((this as any).tmp_current_groups);
      _tmp_current_groups.push(new_group);

      if (dropingRelation_conserv) {
        dropingRelation_conserv.exitId = 0;
        dropingRelation_conserv.groupId = new_id;

        if (_tmp_droppingRelationsList.length == 0) {
          _tmp_droppingRelationsList = [dropingRelation_conserv];
        } else {
          _tmp_droppingRelationsList.push(dropingRelation_conserv);
        }
      }
      (this as any).store_tmp_droppingRelationsList(_tmp_droppingRelationsList);
      (this as any).store_tmp_groups(_tmp_current_groups);

      (this as any).$emit("exits_selected", []);
      (this as any).$emit("groups_selected", []);
      (this as any).snackbar = false;
    },

    merge_group() {
      let new_id = (this as any).generate_new_id(
        (this as any).tmp_current_groups
      );

      let exits_from_groups: number[] = [];

      let groups = (this as any).tmp_current_groups;

      (this as any).groups_selected.forEach(gs => {
        let group = groups.filter(x => x.id == gs)[0];
        exits_from_groups = [...exits_from_groups, ...group.exitIdList];
      });

      let group_name =
        this.$t("distribution.group") +
        " " +
        (this as any).exits_selected
          .concat(exits_from_groups)
          .sort()
          .join("+");

      let new_group: ExitGroup.AsObject = {
        id: new_id,
        name: group_name,
        exitIdList: (this as any).exits_selected.concat(exits_from_groups),
        fillOption: 0
      };
      let _tmp_current_groups = cloneDeep((this as any).tmp_current_groups);
      _tmp_current_groups.push(new_group);
      _tmp_current_groups = _tmp_current_groups.filter(
        x => !(this as any).groups_selected.includes(x.id)
      );

      let dropingRelation_conserv = (this as any).tmp_droppingRelationsList.filter(
        x => x.groupId == (this as any).group_conserv
      );

      // Remove all dropingRelation of exits and groups
      let _tmp_droppingRelationsList = (this as any).tmp_droppingRelationsList.filter(
        x => !(this as any).exits_selected.includes(x.exitId)
      );
      _tmp_droppingRelationsList = _tmp_droppingRelationsList.filter(
        x => !(this as any).groups_selected.includes(x.groupId)
      );

      if (dropingRelation_conserv.length > 0) {
        dropingRelation_conserv.forEach(dr => {
          dr.groupId = new_id;
          if (_tmp_droppingRelationsList.length == 0) {
            _tmp_droppingRelationsList = [dr];
          } else {
            _tmp_droppingRelationsList.push(dr);
          }
        });
      }

      (this as any).store_tmp_droppingRelationsList(_tmp_droppingRelationsList);
      (this as any).store_tmp_groups(_tmp_current_groups);

      (this as any).$emit("exits_selected", []);
      (this as any).$emit("groups_selected", []);
      (this as any).snackbar = false;
    }
  }
});
