



















































import Vue from "vue";
import { mapState, mapActions } from "vuex";
import { cloneDeep } from "lodash";
import { PropType } from "vue/types/umd";

import { Exit, ExitState } from "gs-proto/js/gs/proto/machine_data_pb";
import {ExitGroup } from "gs-proto/js/gs/proto/program_data_pb";
import { DroppingRelation } from "gs-proto/js/gs/proto/program_data_pb";
import { State } from "@/store/model";

import { ExitsUtils } from "../utils/exits_utils";
import Maxweight from "../exit/Maxweight.vue"
import Accumulate from "../exit/Accumulate.vue"

export default Vue.extend({
  name: "groupHeader",
  components:{
      Maxweight,
      Accumulate
  },
  props: {
    group: { type: Object },
    groups_selected: { type: Array },
  },
  computed: {
    ...mapState({
      tmp_droppingRelationsList: state => (state as State).tmp_droppingRelationsList || [],
      tmp_current_groups: state => (state as State).tmp_current_groups || [],
      listExits:  state => (state as State).machine?.exitsList as Exit.AsObject[] }),


  },

  methods: {
    ...mapActions(["change_exit_state", "store_tmp_droppingRelationsList", "store_tmp_groups"]),

  

    remove_from_group(group_id, exit_id) {
      let _tmp_current_groups = cloneDeep((this as any).tmp_current_groups);
      let group_to_remove = cloneDeep(
        _tmp_current_groups.filter(x => x.id == group_id)[0]
      );
      _tmp_current_groups = _tmp_current_groups.filter(x => x.id != group_id );

      let new_list = cloneDeep(group_to_remove.exitIdList);
      new_list.splice(new_list.indexOf(exit_id), 1);
      group_to_remove.name = this.$t("distribution.group") + " " + new_list.sort().join("+");
      group_to_remove.exitIdList = new_list;


      if (new_list.length > 1) {
        _tmp_current_groups.push(group_to_remove);
      }

      (this as any).store_tmp_groups(_tmp_current_groups);

      if (
        new_list.length == 1 &&
        (this as any).tmp_droppingRelationsList.filter(
          x => x.groupId == group_id
        ).length > 0
      ) {

        let _tmp_droppingRelationsList = cloneDeep((this as any).tmp_droppingRelationsList);
        let drl = _tmp_droppingRelationsList.filter(
          x => x.groupId == group_id
        )[0];

        drl.groupId = 0;
        drl.exitId = new_list[0];

        (this as any).store_tmp_droppingRelationsList(
          _tmp_droppingRelationsList.filter(
          x => x.groupId != group_id));
      }
      (this as any).update_list_exits();
    },

    update_list_exits() {
      if ((this as any).listExits) {
        let tmp: Exit.AsObject[] = (this as any).listExits.filter(
          x =>
            !(this as any).tmp_current_groups
              .map(x => x.exitIdList)
              .flat()
              .includes(x.id)
        );

        tmp.sort(function(first, second) {
          if (first.state == 0) return 1;
          if (second.state == 0) return -1;
          return first.id - second.id;
        });
        this.$emit("tmp_listExits", tmp);
      }
    },

    select_group(exit_id, $event) {
      let groups_selected = cloneDeep((this as any).groups_selected);
      if ($event) {
        groups_selected.push(exit_id);
        groups_selected.sort();
      } else {
        groups_selected = groups_selected.filter(x => x != exit_id);
      }
      this.$emit("groups_selected", groups_selected);
    },

    exitToIcon(exit: Exit.AsObject) {
      return ExitsUtils.exitToIcon(exit);
    },

    exitToColor(exit: Exit.AsObject) {
      return ExitsUtils.exitToColor(exit);
    },
    toggle(exit: Exit.AsObject) {
      (this as any).change_exit_state(ExitsUtils.toggle(exit));
    }
  }
});
