





































































import Vue from "vue";
import { mapState, mapActions } from "vuex";
import { cloneDeep } from "lodash";
import { State } from "@/store/model";
import { Package } from "gs-proto/js/gs/proto/program_data_pb";
import Packaging from "@/components/package/Packaging.vue";

export default Vue.extend({
  name: "PackageOption",

  data: () => ({
    tmp_package_type: 0,
    dialog: false
  }),
  components: {
    Packaging
  },
  props: {
    package_type: {
      type: Number
    }
  },
  watch: {
    package_type(v) {
      this.tmp_package_type = v;
    },
    tmp_package_type(v) {
      if (v != this.package_type) {
        this.$emit("package_type", v);
      }
    }
  },
  mounted() {
    this.tmp_package_type = this.package_type;
  },
  computed: {
    ...mapState({
      all_packages: state => (state as State).packages as Package.AsObject[]
    }),
    show_package: function() {
      return (this as any).tmp_package_type != 0;
    }
  },
  methods: {
    change_switch(event) {
      if (this.show_package) {
        this.tmp_package_type = 0;
      } else {
        this.tmp_package_type = 1;
      }
    }
  }
});
