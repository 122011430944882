/**
 * Vue Router
 *
 * @library
 *
 * https://router.vuejs.org/en/
 */

// Lib imports
import Vue from "vue";
import Router from "vue-router";
import Meta from "vue-meta";

// Routes
import paths from "./paths";
import store from "@/store";
import  FilterCreate from '../components/classes/dialogs/FilterCreate.vue';

Vue.component('filter-create', FilterCreate);


function route(path, view, name, meta) {
  return {
    name: name || view,
    path,
    component: (resovle) => import(`@/views/${view}.vue`).then(resovle),
    meta: meta,
  };
}

Vue.use(Router);

// Create a new router
const router = new Router({
  mode: "hash",
  routes: paths
    .map((path) => route(path.path, path.view, path.name, path.meta))
    .concat([{ path: "*", redirect: "/dashboard" }]),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
});

Vue.use(Meta);

export default router;
