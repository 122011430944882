<template>
  <v-app :style="$vuetify.theme.dark ? 'background:#2b2a2a': 'background:#f5f5f5'">
    <v-navigation-drawer
      v-if="websocket_connected"
      :class="$vuetify.theme.dark ? 'bar' : 'bar'"
      dark
      app
      permanent
      expand-on-hover
      :mini-variant.sync="mini"
    >
      <v-list dense nav>
        <v-list-item v-if="mini" class="px-0">
          <v-img max-width="40" :src="van_logo"></v-img>
        </v-list-item>

        <v-list-item v-else class="text-center">
          <v-img max-width="80" :src="van_logo"></v-img>
          <span class="app-name ml-2 black--text">{{$t('application.title')}}</span>
        </v-list-item>

        <v-list-item class="top-center mb-4">
          <v-list-item-icon>
            <v-icon size="180%" color="lighted">mdi-view-dashboard-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="text-button text-capitalize lighted--text">
              <b>{{$t("dashboard.title")}}</b>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <settings-dialog/>
        <class-dialog  class="mb-5 mt-5"/>
        <package-dialog class="mb-5 mt-5"/>
        <distribution-dialog />
      </v-list> 
    </v-navigation-drawer>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import { mapState, mapActions } from "vuex";

import PackageDialog from "./components/package/PackageDialog";
import SettingsDialog from "./components/settings/SettingsDialog";
import ClassDialog from "./components/classes/ClassDialog";
import DistributionDialog from "./components/distribution/DistributionDialog";

export default {
  name: "App",
  components: {
    PackageDialog,
    SettingsDialog,
    ClassDialog,
    DistributionDialog
  },

  data: () => ({
    mini: true,
    van_logo: "static/logo-VanWamel.png"
  }),

  computed: {
    ...mapState({
      websocket_connected: state => state.ws_connected || false
    })
  },
  created() {
    window.onbeforeunload = function() {
      localStorage.clear();
      return "Are you sure you want to close the window?";
    };
  }
};
</script>

<style>
.top-center {
  margin-top: 25vh;
}
</style>
