import { h } from 'vue'

export const ColumnFunctions = {
  
  getrenderHeaderNameCell: function (title) {
    return ({ column, b }, h) => {
      return (
        <span class="text-capitalize">{title} </span>
      );
    };
  },

  getrenderHeaderCell: function (title) {
    return ({ column, b }, h) => {
      let r = [];
      r.push( h('span', {}, title))
      if (column.parent == 40000 ){
        r.push( h('filter-create', {props :  {filterColumn: column}}))

      } 
      else if (column.unit != "" || column.unit == undefined  || column.unit == 'undefined' || column.unit.length >0 ){
        r.push( h('span', {}, ' (' + column.unit + ')'));
      };
      return r;
    };
  },

  getRenderBodyCellColor: function () {
    return ({ row, column, rowIndex }, h) => {
      let cellData = row[column.field];
      let min_hue = cellData[0];
      let max_hue = cellData[1];
      let r = [];
      if (min_hue== 0 && max_hue == 100){
        r.push(<span class="percent-span-container ml-2"> Any </span>)
      }else{
        r.push(<span class="percent-span-container ml-2"> <b>{min_hue}</b> to <b>{max_hue}</b></span>)
      

      let min_hue2 = cellData[2];
      let max_hue2 = cellData[3];
      if (min_hue2 && min_hue2 != 0 && min_hue2 != max_hue2){
        r.push(<br></br>)
        r.push(<span class="percent-span-container ml-2"> <b>{min_hue2}</b> to <b>{max_hue2}</b></span>)
      }
    }

      return r;
    };
  },

  getRenderBodyCellColorSurface: function () {
    return ({ row, column, rowIndex }, h) => {
      let cellData = row[column.field];
      let min_surface = cellData[0];
      let max_surface = cellData[1];
      let r = [];
      if (min_surface== 0 && max_surface == 0){
        r.push(<span class="percent-span-container ml-2"> - </span>)
      } else {
        r.push(<span class="percent-span-container ml-2">  <b>{min_surface}</b> to <b> {max_surface} </b><small> %  </small>  </span>)
      }
      return r;
    };
  },

  getRenderBodyCellRange: function (greater, less, to, any) {
    return ({ row, column, rowIndex }, h) => {
      let cellData = row[column.field];

      if (cellData.length == 0) {
        return <div> - </div>
      }
      if (Array.isArray(cellData[0])) {
        if (cellData.length == 1) {
          cellData = cellData[0]
        } else {
          let r = []
          cellData.forEach(cd => {
            r.push(<div class="percent-span-container">
              <b>{cd[0]} </b> {to} <b> {cd[1]} </b>{" "}
            </div>)

          })
          return r
        }
      }
      if (cellData[0] == 0 && cellData[1] == column.max) {
        return <div class="text-capitalize"> {any}</div>;
      }
      if (cellData[0] == 0) {
        return (
          <div style="font-weight:400; font-size:0.85rem;">
            {" "}
            {less} <b>{cellData[1]} </b>{" "}
          </div>
        );
      }
      if (cellData[1] >= parseFloat(column.max)) {
        return (
          <div style="font-weight:400; font-size:0.85rem;">
            {" "}
            {greater} <b>{cellData[0]} </b>{" "}
          </div>
        );
      }
      return (
        <div class="percent-span-container">
          <b>{cellData[0]} </b> {to} <b> {cellData[1]} </b>{" "}
        </div>
      );
    };
  }
};
