


























































import Vue from "vue";
import { mapState, mapActions } from "vuex";
import { fields_meta } from "gs-proto/js/gs/proto/categories_data_meta";
import { Clazz } from "gs-proto/js/gs/proto/program_data_pb";
import { DroppingRelation } from "gs-proto/js/gs/proto/program_data_pb";
import { ExitGroup } from "gs-proto/js/gs/proto/program_data_pb";
import { State } from "@/store/model";

import { cloneDeep } from "lodash";
import draggable from "vuedraggable";

import { PropType } from "vue/types/umd";
import ClassInfo from "./ClassInfo.vue"

export default Vue.extend({
  name: "classDraggable",
  components: {
    draggable,
    ClassInfo
  },
  data: () => ({
    fields_meta,
    filter_str: ""
  }),

  computed: {
    ...mapState({
       classList:  state => (state as State).program?.objectCategoryProgramsList[0].classesList,
       tmp_droppingRelationsList: state => (state as State).tmp_droppingRelationsList || [],
       tmp_current_groups: state => (state as State).tmp_current_groups || []
    }),

    tmp_classList() {
      let remaining_class_name = (this as any).$t("distribution.remaining");
      if (this.classList != undefined) {
        let cl = cloneDeep(this.classList);
        cl = cl.filter(x =>
          x.name.toLowerCase().includes(this.filter_str.toLowerCase())
        );
        cl.push({ id: 0, name: remaining_class_name });
        cl.push({ id: -1, name: "Unassigned" });
        return cl;
      }
      return [{ id: 0, name: remaining_class_name }];
    }
  },
  methods: {
    get_percentage(class_id){
      let total = 0
      let clazz_percentages_mix_exit_flow = this.tmp_droppingRelationsList.filter(x=> x.mixOption == 0).map(x=>x.classPercentageList.filter(l => l.classId == class_id)[0])
      clazz_percentages_mix_exit_flow.forEach(p => {
        if (p){
          if( p.percentage > 0 ){
            total = 5 // symbolic cause we dont know the % of the incoming flow
          }
        }
      })

      let clazz_percentages = this.tmp_droppingRelationsList.filter(x=> x.mixOption != 0).map(x=>x.classPercentageList.filter(l => l.classId == class_id)[0])  
      clazz_percentages.forEach(p => {
        if (p){
          if( p.percentage == -1){
            total = 100
          }else{
            total += p.percentage
          }
        }
      })
      return total > 100 ? 100 : total;
    },

    get_group_classes(classesMixList): String {
      let classes_ids = classesMixList.map(x => x.classId);
      return classes_ids
        .map(x => this.classList!.filter(y => y.id == x)[0].name)
        .join(" + ");
    }
  }
});
