
import paper from "paper";

function inside(point, w, h) {
    if (point.x < 0) {
        point.x = 0
    } else if (point.x > w) {
        point.x = w
    }
    if (point.y < 0) {
        point.y = 0
    } else if (point.y > h) {
        point.y = h
    }
    return point
}

 const toolEdit = (scope) => {
    const tool = new scope.Tool()
    var segment;
    var hitOptions = {
        segments: true,
        tolerance: 20
    };
    tool.name = 'edit'

    tool.onMouseDown = function (event) {
        segment = null;
        var hitResult = scope.project.hitTest(event.point, hitOptions);
        if (!hitResult) {
            return;
        } else {
            segment = hitResult.type == 'segment' ? hitResult.segment : null
            if (segment.getPath().type == 'color_range') {
                segment = null; // not possible to edit color range
            }
        }
    }

    tool.onMouseDrag = function (event) {
        if (segment) {
            segment.point = inside(event.point, this.w, this.h)
            segment.selected = true;
        }
    }

    tool.setWH = function (scope, w, h) {
        this.scope = scope;
        this.w = w;
        this.h = h;
    }
    return tool
}

export default toolEdit;