































































import Vue from "vue";
import { mapState, mapActions } from "vuex";
import { cloneDeep } from "lodash";

import {
  Program,
  DroppingRelation
} from "gs-proto/js/gs/proto/program_data_pb";
import { State } from "@/store/model";
import { ExitGroup } from "gs-proto/js/gs/proto/program_data_pb";

import CancelSnackbar from "../CancelSnackbar.vue";
import Distribution from "./Distribution.vue";

import { MappingFunction } from "../../store/proto_utils/mapping";

export default Vue.extend({
  name: "distributionDialog",
  components: { CancelSnackbar, Distribution },
  data: () => ({
    snackbar_cancel: false,
    dialog: false,
    alert: false,
    timeout: 5000,
    msg: ""
  }),
  props: {
    as_card: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      current_program: state => (state as State).program,
      websocket_connected: state => (state as State).ws_connected,
      droppingRelationsList: state => (state as State).program?.droppingRelationsList as DroppingRelation.AsObject[],
      tmp_droppingRelationsList: state => (state as State).tmp_droppingRelationsList || [],
    })
  },
  // watch:{
  //   websocket_connected(val){
  //     if (!val){
  //       this.dialog = false
  //     }
  //   }
  // },
  watch: {
    dialog(v) {
      if (v) {
        let distribution_c: any = this.$refs.distributionComponent as Vue;
        if (distribution_c) {
          distribution_c.mount();
        }
      }
    }
  },
  methods: {
    apply() {
      let distribution_c: any = this.$refs.distributionComponent as Vue;
      if (distribution_c) {
        if (this.current_program) {
          let updated_program: Program.AsObject = cloneDeep(
            this.current_program
          );
          updated_program.droppingRelationsList =
            distribution_c.tmp_droppingRelationsList;
          updated_program.exitGroupsList = distribution_c.tmp_current_groups;

          let _program: Program = MappingFunction.programToProto(
            updated_program
          );
          this.$store.dispatch("create_or_update_program", _program);
        }
        this.dialog = false;
      }
    },

    cancel() {
      this.snackbar_cancel = true;
    },

    continue_cancel() {
      let distribution_c: any = this.$refs.distributionComponent as Vue;
      if (distribution_c) {
        distribution_c.mount();
      }
      this.dialog = false;
    }
  }
});
