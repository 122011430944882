












































import Vue from "vue";
import { mapState, mapActions } from "vuex";
import { cloneDeep } from "lodash";
import { State } from "@/store/model";

import Packaging from "./Packaging.vue";

export default Vue.extend({
  name: "settingsDialog",
  components: { Packaging },

  data: () => ({
    dialog: false
  }),

  computed: {
    ...mapState({
      current_program: state => (state as State).program
    })
  },

  methods: {
    cancel() {
      this.dialog = false;
    },
    save() {
      this.dialog = false;
    }
  }
});
