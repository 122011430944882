
import paper from "paper";

class ToolStack {
    constructor(tools, scope) {
        this.tools = tools.map(tool => tool(scope))
    }
    activateTool(name, scope, w, h) {
        try {
            const tool = this.tools.find(tool => tool.name === name)
            tool.setWH(scope, w, h);
            tool.activate()
        } catch (e) {
            // tools not defined
            console.log('error activating tool', name, ': ', e)
        }

    }
}

export default ToolStack;
