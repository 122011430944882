import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";

import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import initial_state from "./state";

import wsConnect from "./backend/websocket";

const debug = process.env.NODE_ENV !== "production";

Vue.use(Vuex);

// Create a new store
const store = new Vuex.Store({
  actions,
  getters,
  mutations,
  state: initial_state,
  modules: {},
  strict: debug,
});
// export store
export default store;

wsConnect(store);
