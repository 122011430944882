









































































































































































import Vue from "vue";
import { mapState, mapActions } from "vuex";
import { cloneDeep } from "lodash";
import draggable from "vuedraggable";
import { Exit, ExitState } from "gs-proto/js/gs/proto/machine_data_pb";
import { Clazz, ExitGroup } from "gs-proto/js/gs/proto/program_data_pb";
import { DroppingRelation } from "gs-proto/js/gs/proto/program_data_pb";
import { State } from "@/store/model";

import Grid from "@/components/Grid.vue";
import ClassDraggable from "./draggable/ClassDraggable.vue";
import ExitDraggable from "./draggable/ExitDraggable.vue";
import ExitDinamicDraggable from "./draggable/ExitDinamicDraggable.vue";

import MixComponent from "./draggable/Mix.vue";
import { ExitsUtils } from "./utils/exits_utils";

import MixEdit from "./edit_dialogs/MixEdit.vue";

import GroupingAlert from "./groups/GroupingAlert.vue";
import GroupHeader from "./groups/GroupHeader.vue";
import Maxweight from "./exit/Maxweight.vue"
import Accumulate from "./exit/Accumulate.vue"

import { Colors } from "./draggable/mix/colors";


export default Vue.extend({
  name: "distribution",
  components: {
    MixComponent,
    GroupHeader,
    draggable,
    ClassDraggable,
    ExitDraggable,
    ExitDinamicDraggable,
    Grid,
    MixEdit,
    GroupingAlert,
    Maxweight,
    Accumulate
  },
  data() {
    return {
      filter_str:'',
      snackbar_group: false,
      cols: 4,
      tmp_list: [] as any[],
      mixes: [] ,//as Mix.AsObject[],
      exits_selected: [] as number[],
      groups_selected: [] as number[],
      tmp_listExits: [] as Exit.AsObject[],
      default_exit_id: undefined
    };
  },

   props: {
    as_card: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState({
      listExits:  state => (state as State).machine?.exitsList as Exit.AsObject[],
      current_groups: state => (state as State).program?.exitGroupsList as ExitGroup.AsObject[],
      classList:  state => (state as State).program?.objectCategoryProgramsList[0].classesList as Clazz.AsObject[],
      droppingRelationsList: state => (state as State).program?.droppingRelationsList as DroppingRelation.AsObject[],
      tmp_droppingRelationsList: state => (state as State).tmp_droppingRelationsList || [],
      tmp_current_groups: state => (state as State).tmp_current_groups || []
    }),
  },
  watch: {
    listExits(v) {
      this.update_list_exits();
    }
  },
  mounted() {
    (this as any).mount();
  },

  methods: {
    ...mapActions(["change_exit_state", "store_tmp_droppingRelationsList", "store_tmp_groups"]),

    get_remainings() {
      let remainings = {};
      let classes = (this as any).classList;
      for (let i in classes) {
        remainings[classes[i].id] = 100;
      }

      this.tmp_droppingRelationsList.forEach(dr => {
        dr.classPercentageList.forEach(cp => {
          if (dr.mixOption == 1) {
            if (cp.percentage == -1) {
              remainings[cp.classId] -= 100;
            } else {
              remainings[cp.classId] -= cp.percentage;
            }
          }
        });
      });
      (this as any).store_tmp_droppingRelationsList( (this as any).tmp_droppingRelationsList)
      let final_remainings = [] as any;

      for (let i in remainings) {
        if (remainings[i] > 0) {
          final_remainings.push(
            classes.filter(x => x.id == i)[0].name + " (% unassigned)"
          );
        }
      }
      if (final_remainings.length > 0) {
        return true
      } else {
        return false
      }
    },

    get_filtered_groups(){
      let filter_str = (this as any).filter_str
      return (this as any).tmp_current_groups.filter(x=>  x.name.includes(filter_str))
    },

    get_filtered_exits(){
      let groups = (this as any).tmp_current_groups
      let exits = (this as any).tmp_listExits.filter(x => !groups.map(x => x.exitIdList).flat().includes(x.id))
      let filter_str = (this as any).filter_str
      return exits.filter(x=> String(x.id).includes(filter_str))
    },


    remaining_percentage(){
      let remaining  = 100;
       (this as any).tmp_droppingRelationsList.forEach(element => {
         if (element.classPercentageList.filter(x=>x.classId==0).length > 0 )
         remaining -= element.classPercentageList.filter(x=>x.classId==0)[0].percentage
       });
        (this as any).store_tmp_droppingRelationsList( (this as any).tmp_droppingRelationsList);
      return remaining
    },

    mount: function(): void {
        (this as any).store_tmp_droppingRelationsList( (this as any).droppingRelationsList);
        (this as any).store_tmp_groups( (this as any).current_groups);
        (this as any).update_list_exits();
    },

    update_list_exits() {
      if (this.listExits) {
        let tmp: Exit.AsObject[] = this.listExits.filter(
          x =>
            !this.tmp_current_groups
              .map(x => x.exitIdList)
              .flat()
              .includes(x.id)
        );

        tmp.sort(function(first, second) {
          if (first.state == 0) return 1;
          if (second.state == 0) return -1;
          return first.id - second.id;
        });

        this.tmp_listExits = tmp;
      }
    },

    select_exit(exit_id, $event) {
        if ($event) {
          this.exits_selected.push(exit_id);
          this.exits_selected.sort();
        } else {
          this.exits_selected = this.exits_selected.filter(x => x != exit_id);
        }
    },

    merge_group() { (this as any).snackbar_group = true },
    create_group() {  (this as any).snackbar_group = true },

    exitToIcon(exit: Exit.AsObject) {
      return ExitsUtils.exitToIcon(exit);
    },
    exitToClass(exit: Exit.AsObject) {
      return ExitsUtils.exitToClass(exit);
    },
    exitToColor(exit: Exit.AsObject) {
      return ExitsUtils.exitToColor(exit);
    },
    toggle(exit: Exit.AsObject) {
      this.change_exit_state(ExitsUtils.toggle(exit));
    }
  }
});
