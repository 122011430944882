













































































































































import Vue from "vue";
import { mapState, mapActions } from "vuex";
import { cloneDeep } from "lodash";
import { PropType } from "vue/types/umd";
import { Plotly } from "vue-plotly";

import { Exit, ExitState } from "gs-proto/js/gs/proto/machine_data_pb";
import {
  ExitGroup,
  Clazz
} from "gs-proto/js/gs/proto/program_data_pb";
import { State } from "@/store/model";

import MixEdit from "../edit_dialogs/MixEdit.vue";
import { Colors } from "./mix/colors";

export default Vue.extend({
  name: "mixComponet",
  components: {
    Plotly,
    MixEdit
  },
  data() {
    return {
      dialog: false,
      snackbar_group: false,
      layout: {
        colorway: new Colors().colors,
        paper_bgcolor: "rgba(0,0,0,0)",
        plot_bgcolor: "rgba(0,0,0,0)",
        autosize: true,
        showlegend: false,
        margin: {
          t: 5,
          r: 1,
          l: 1,
          b: 5
        }
      },
    };
  },
  props:{
      tmp_droppingRelationsList:{type:Array},
      group:{type: Object},
      drop_exit: {type: Object},
      cols: {type: Number, default:6},
      is_group:{type: Boolean}
  },

  computed: {
    ...mapState({
      classList:  state => (state as State).program?.objectCategoryProgramsList[0].classesList as Clazz.AsObject[],
    }),

    color_map() {
      let classes_ids = this.classList.map(x => x.id).sort();
      let colors = classes_ids.map(
        x => (this as any).layout.colorway[classes_ids.indexOf(x)]
      );
      let color_map = {};
      this.classList.forEach(x => {
        color_map[x.id] = colors[classes_ids.indexOf(x.id)];
      });
      return color_map;
    },
     fill_options() {
      //TODO
      return [
        { value: 0, text: (this as any).$t("distribution.onebyone") },
        { value: 1, text: (this as any).$t("distribution.simultaneously") },
        { value: 2, text: (this as any).$t("distribution.dynamic") }
      ];},
  },

  methods: {
     fillOptionIcon(group){
      if (group.fillOption == 0 ) { return 'mdi-ray-start-arrow'}
      return 'mdi-multicast' 
    },

    fillOptionText(group){
      return (this as any).fill_options.filter(x=> x.value==group.fillOption)[0].text
    },

    edit() {
      (this as any).dialog = true;
    },

   clear(){
       this.$emit("clear")
   },

  generate_data_plot(classes_mix) {
      let classes: any = this.classList;
      let classes_ids = this.classList.map(x => x.id).sort();
      let labels = classes_mix.map(
        x => classes.filter(k => k["id"] == x.classId)[0]["name"]
      );
      let ids = classes_mix.map(
        x => classes.filter(k => k["id"] == x.classId)[0]["id"]
      );
      let colors = new Colors().colors;
      return [
        {
          values: classes_mix.map(x => x.percentage),
          labels: labels,
          type: "pie",
          hoverinfo: "label+percent",
          textinfo: "none",
          marker: {
            colors: ids.map(x => colors[classes_ids.indexOf(x)])
          }
        }
      ];
    },
  }
});
