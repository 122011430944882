import axios, { AxiosResponse } from "axios";
import { ApplicationInfo } from "gs-proto/js/gs/proto/commons_data_pb";
import {
  UIChangeRequest,
  UIChangeReply,
} from "gs-proto/js/gs/proto/ui_data_pb";

const $axios = axios.create({
  baseURL: "/api/",
  timeout: 5000,
  headers: { "Content-Type": "application/octet-stream" },
});

const client = new ApplicationInfo();
client.setId("gs-ui");
// TODO: set client version

const requestChange = async function (
  change_request: UIChangeRequest
): Promise<void | UIChangeReply.AsObject> {
  change_request.setClient(client);
  const response = await $axios({
    url: "request-change",
    method: "POST",
    data: change_request.serializeBinary(),
    headers: { "Content-Type": "multipart/form-data" },
    responseType: "arraybuffer",
  })
    .then(function (http_response: AxiosResponse) {
      const change_reply = UIChangeReply.deserializeBinary(
        http_response.data
      ).toObject();
      console.log("http_post - then - change_reply:");
      console.log(change_reply);
      return change_reply;
    })
    .catch(function (error) {
      if (error.response) {
        const change_reply = UIChangeReply.deserializeBinary(
          error.response.data
        ).toObject();
        console.log("http_post - error - change_reply:");
        console.log(change_reply);
        return change_reply;
      } else if (error.request) {
        // TODO: handle when the request was made but no response was received
        console.log(error.request);
      } else {
        // TODO: handle when something happened in setting up the request that
        // triggered an Error
        console.log("Error", error.message);
      }
    });
  return response;
};


export { requestChange };
