/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */
export default [
  {
    path: "/dashboard",
    // Relative to /src/views
    view: "Dashboard",
  },
  {
    path: "/table",
    // Relative to /src/views
    view: "Table",
  },
];
