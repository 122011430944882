
import paper from "paper";

let base_color = "#161817"
let selected_color =  "#f1f6ff"

function get_class_color() {
    return new paper.Color(base_color)
}

function remove_circles(scope) {
    console.log('removin circles', scope);
    scope.project.getItems({ class: scope.Path }).filter((x) => x.name == 'circle_tmp').forEach(function (elem) { elem.remove() })
}

function close_path(path, scope) {
    path.closed = true;
    path.fillColor = base_color
    path.fillColor.alpha = 0.1
    path.strokeColor.alpha = 1
    remove_circles(scope)
}

function create_polyline(event, scope, path, w) {
    let marg = w * 0.03
    if (path.segments.length > 2 && Math.abs(path.segments[0].curve.point1.x - event.point.x) < marg && Math.abs(path.segments[0].curve.point1.y - event.point.y) < marg) {
        close_path(path,scope)
        return false
    }
    path.add(event.point)
    let circle = new scope.Path.Circle({
        center: event.point,
        radius: w * 0.0055 * 1.5,
        fillColor: base_color,
        name: 'circle_tmp'
    })
    circle.removeOn({
        drag: false,
        up: false
    })

    return true
}
function create_new_path(scope) {
    let path = new scope.Path()
    path.type = 'polygon'
    path.selectedColor = new paper.Color(selected_color)
    path.fillColor = new paper.Color(base_color)
    path.fillColor.alpha = 0
    return path
}

const toolPolygon = (scope) => {
    const tool = new scope.Tool()
    tool.name = 'polygon'

    tool.onKeyDown = function (event) {

        switch (event.key) {
            case "delete":
                remove_circles(this.scope)
                this.path.remove()
                this.path = create_new_path( this.scope)
                break;

            case "enter":
                close_path(this.path, this.scope)
                this.path = create_new_path( this.scope)
                break;
        }
    }

    tool.onMouseUp = function (event) {
        if (!create_polyline(event, this.scope , this.path, this.w)) {
            this.path = create_new_path( this.scope )
        } else {
            this.path.strokeColor = get_class_color()
            this.path.strokeColor.alpha = 1
            this.path.strokeWidth = 2
            this.path.fillColor.alpha = 0
        }
    }
    tool.setWH = function (scope, w, h) {
        this.scope = scope;
        this.w = w;
        this.h = h;
        this.path = create_new_path( this.scope)
    }
    return tool
}

export default toolPolygon;