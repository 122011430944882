














































































import Vue from "vue";
import { mapState, mapActions } from "vuex";
import { cloneDeep } from "lodash";
import { Exit, ExitState } from "gs-proto/js/gs/proto/machine_data_pb";
import { Clazz, ExitGroup } from "gs-proto/js/gs/proto/program_data_pb";
import { DroppingRelation } from "gs-proto/js/gs/proto/program_data_pb";
import { State } from "@/store/model";

import { ColumnFunctions } from "./utils-js/render_columns.js";
import { ClazzTransformFunctions } from "./utils-js/clazz_transform_data";

import dialogFields from "./dialogs/FieldsDialog.vue";
import rowDialog from "./dialogs/RowDialog.vue";

export default Vue.extend({
  name: "classTable",
  components: {
    dialogFields,
    rowDialog
  },

  data: () => ({
    snackbar: false,
    columns: [],
    availableFields: [],
    active_columns: [],
    currentTableData: [],
    tableData: [],
    selected_rows: [],
    selected_column: undefined,
    selected_value: undefined,
    dialog: false,
    rowStyleOption: {
      stripe: true,
      clickHighlight: true,
      hoverHighlight: true
    }
  }),
  computed: {
     ...mapState({
      droppingRelationsList: state => (state as State).program?.droppingRelationsList as DroppingRelation.AsObject[],
       tmp_current_filters: state => (state as State).tmp_current_filters,
    }),
    alert_msg(){
      let alert_msg = [] as any;
      for (let idx in (this as any).tableData) {
          let found = false;
          let row_id = (this as any).tableData[idx].id;
          for (let dr_idx in (this as any).droppingRelationsList) {
                let dr =  (this as any).droppingRelationsList[dr_idx];
                let finded = dr.classPercentageList.find( ({ classId }) => classId === row_id )
                if (finded != undefined) {
                  found = true;
                  let clazzName = (this as any).tableData.filter(x=> x.id == row_id)[0].name; 
                  alert_msg.push(clazzName);
                  break
                }
          }
      }
      return alert_msg;
    },
    basic_columns() {
      return [
        {
          field: "",
          key: "check",
          type: "checkbox",
          title: "",
          width: 50
        },
        {
          field: "name",
          key: "name",
          title: "Name",
          type: "string",
          sortBy: "",
          renderHeaderCell: ColumnFunctions.getrenderHeaderNameCell(
            ((this as any) as any).$t("proto.name")
          )
        }
      ];
    }
  },
  watch: {
    dialog() {
      (this as any).update_default_class();
    },

    columns(val) {
      // Add columns
      val.forEach(column => {
        if (column.active) {
          let field = column.field;
          let column_range = [0, 100];
          if (column.type == "range") {
            column_range = [0, column.max];
          }
          if (column.type == "color") {
            column_range = [0, 100,0,0];
          }
          if (column.type == "colorsurface") {
            column_range = [0, 0];
          }
          (this as any).tableData.forEach(row => {
            if (!Object.keys(row).includes(field) && field != "") {
              row[field] = column_range;
            }
          });
        }
      });

      // Remove columns
      let _tmp_current_filters=  cloneDeep((this as any).tmp_current_filters);
      (this as any).tableData.forEach(row => {
        val.forEach(column => {
          if (!column.active) {
            let field = column.field;
            let idx = -1
            // CHECK FILTER
            for (var i=0; i<_tmp_current_filters.length; i++) {
              if (_tmp_current_filters[i].getField() == column.id) {
                              idx = i
              }
            }
            if (idx > -1){ _tmp_current_filters.splice(idx, 1);}    
            delete row[field];
          }
        });
      });
      (this as any).store_tmp_filters(_tmp_current_filters);
      
      (this as any).active_columns = [].concat.apply(
        (this as any).basic_columns,
        (this as any).columns.filter(x => x.active)
      );
      // (this as any).$emit("tableData", (this as any).tableData);
    }
  },
  mounted() {
    (this as any).mount();
  },

  methods: {
    ...mapActions([ "store_tmp_filters"]),
    
    update_default_class() {
      var mergeRanges = require("merge-ranges");
      let default_class_row = (this as any).tableData.filter(x => x.id == 0)[0];
      let n = cloneDeep((this as any).tableData).filter(x => x.id != 0);
      let columns = Object.keys(n[0]).filter(x => x != "name" && x != "id");

      columns.forEach(c => {
        if( c.includes('color') ){   
          default_class_row[c] = c == 'average-color'? [0,100, 0,0] : [0,0];
        }else{
        let total_range = [
          (this as any).columns.filter(x => x.field == c)[0].min,
          (this as any).columns.filter(x => x.field == c)[0].max
        ];
        if (n.map(x => x[c]).some((el) => el === undefined)){
           default_class_row[c] = []; // case 1: full cover
           return;
        }
        let covered_ranges = mergeRanges(n.map(x => x[c]));
      
        if (
          covered_ranges.length == 1 &&
          covered_ranges[0][0] == total_range[0] &&
          covered_ranges[0][1] == total_range[1]
        ) {
          default_class_row[c] = []; // case 1: full cover
        } else {
          let ranges_no_covered = [] as any;
          let min = total_range[0];
          covered_ranges.forEach(cr => {
            if (min != cr[0] && min < cr[0]) {
              let new_r = [min, cr[0]];
              ranges_no_covered.push(new_r);
            }
            min = cr[1];
          });
          if (min != total_range[1]) {
            ranges_no_covered.push([min, total_range[1]]);
          }
          // console.log(c, n, ranges_no_covered)
          default_class_row[c] = ranges_no_covered;
        }
        }
      });
    },
    mount() {
      let product_id = (this as any).$store.getters.productParentId;
      (this as any).columns = cloneDeep((this as any).$store.getters.availableFields);

      (this as any).currentTableData = cloneDeep(
        (this as any).$store.state.program.objectCategoryProgramsList[0].classesList
      );
      (this as any).tableData = ClazzTransformFunctions.toObject((this as any).currentTableData);

      (this as any).add_default_class();

      (this as any).columns.forEach(x => {
        x.key = x.title;
        x.field = x.title;
        x.active = false;
        x.active = Object.keys((this as any).tableData[0]).includes(x.title);
        x.fixed = "none";
        if (x.type == 3) {
          x.type = "color";  
        } else if (x.type == 4) {
          x.type = "colorsurface";  
        }else if (x.oc_specs.map(i => i.oc).includes(product_id)) {
          x.unit = x.oc_specs.filter(i => i.oc == product_id)[0].range.unit;
          x.max = x.oc_specs.filter(i => i.oc == product_id)[0].range.max; //TODO
          x.min = x.oc_specs.filter(i => i.oc == product_id)[0].range.min; // TODO
          x.type = "range";
        } else {
          x.type = "out"; // never
        }
      });

      // functions to render columns in table

      let greater = (this as any).$t("classification.greater");
      let less = (this as any).$t("classification.less");
      let to = (this as any).$t("classification.to");
      let any = (this as any).$t("classification.any");

      for (let i = 0; i < (this as any).columns.length; i++) {
        (this as any).columns[i].align = "center";
        (this as any).columns[i].sortBy = "";

        if ((this as any).columns[i].type == "range") {
          (this as any).columns[i].fixed = "none";
          (this as any).columns[i][
            "renderHeaderCell"
          ] = ColumnFunctions.getrenderHeaderCell(
            (this as any).$t("proto." + (this as any).columns[i].title)
          );
          (this as any).columns[i][
            "renderBodyCell"
          ] = ColumnFunctions.getRenderBodyCellRange(greater, less, to, any);
        } else if ((this as any).columns[i].type == "color") {
          (this as any).columns[i].fixed = "none";
          (this as any).columns[i][
            "renderHeaderCell"
          ] = ColumnFunctions.getrenderHeaderCell(
            (this as any).$t("proto." + (this as any).columns[i].title)
          );
          (this as any).columns[i][
            "renderBodyCell"
          ] = ColumnFunctions.getRenderBodyCellColor(
          );
        }
        else if ((this as any).columns[i].type == "colorsurface") {
          (this as any).columns[i].fixed = "none";
          (this as any).columns[i][
            "renderHeaderCell"
          ] = ColumnFunctions.getrenderHeaderCell(
            (this as any).$t("proto." + (this as any).columns[i].title)
          );
          (this as any).columns[i][
            "renderBodyCell"
          ] = ColumnFunctions.getRenderBodyCellColorSurface(
          );
        }

      }
      (this as any).active_columns = [].concat.apply(
        (this as any).basic_columns,
        (this as any).columns.filter(x => x.active)
      );
       (this as any).update_default_class()
    },

    delete_row() {
      (this as any).snackbar = true;
    },
    // classes_in_distribution(){
    //   let class_ids =  new Set()
    //   for (let dr_idx in (this as any).droppingRelationsList) {
    //     for (let c in (this as any).droppingRelationsList[dr_idx].classPercentageList){
    //         let cl_id = (this as any).droppingRelationsList[dr_idx].classPercentageList[c].classId;
    //         class_ids.add(cl_id);  
    //     }            
    //   }
    //   // let set_class= new Set(classId);
    //   return [...class_ids];
    // },
    
    remove() {
      let remove_rows = [] as any;
      for (let idx in (this as any).selected_rows) {
          let found = false;
          let row_name = (this as any).selected_rows[idx];
          let row_id = (this as any).tableData.filter(x=> x.name == row_name)[0].id;
        
          for (let dr_idx in (this as any).droppingRelationsList) {
                let dr =  (this as any).droppingRelationsList[dr_idx];
                let finded = dr.classPercentageList.find( ({ classId }) => classId === row_id )
                if (finded != undefined) {
                  found = true;
                  let clazzName = (this as any).tableData.filter(x=> x.id == row_id)[0].name; 
                  break
                }
          }
          if (!found) { 
            remove_rows.push(row_name)
          }
      }
      (this as any).tableData = (this as any).tableData.filter(
        x => !remove_rows.includes(x.name)
      );
      (this as any).add_default_class();
      (this as any).snackbar = false;
    },

    add_default_class() {
      if ((this as any).tableData.filter(x => x.id == 0).length < 1) {
        let default_row = {
          id: 0,
          name: (this as any).$t("classification.default_class")
        };
        (this as any).tableData.push(default_row);
      }
    },

    generate_new_class_id() {
      let class_ids = (this as any).tableData.map(x => x.id);
      let date = new Date();
      let components = [
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
        date.getMilliseconds()
      ];
      let new_id = parseInt(components.join(""));
      while (class_ids.includes(new_id)) {
        new_id += 1;
      }
      return new_id;
    },

    duplicate_row() {
      for (let i in (this as any).selected_rows) {
        if ((this as any).selected_rows[i] != (this as any).$t("classification.default_class")) {
          let name = (this as any).selected_rows[i];
          let r = cloneDeep((this as any).tableData.filter(x => x.name == name)[0]);
          r["name"] = r["name"] + "_copy";
          while ((this as any).tableData.filter(x => x.name == r["name"]).length > 0) {
            r["name"] = r["name"] + "_copy";
          }
          r["id"] = (this as any).generate_new_class_id();
          (this as any).tableData.unshift(r);
        }
      }
    },

    create_new_class() {
      let class_names = (this as any).tableData.map(x => x.name);
      let cont = 1;
      let new_name = "class " + cont;
      while (class_names.includes(new_name)) {
        cont += 1;
        new_name = "class " + cont;
      }
      let new_row = {
        id: (this as any).generate_new_class_id(),
        name: new_name
      };
      (this as any).columns.forEach(col => {
        if (col.active) {
          let column_range = [] as any;
           if (col.type == "range") {
            column_range = [col.min, col.max];
          }
          
          if (col.type == "bool") {
            column_range = col.bool;
          }

          if (col.type == "color") {
            column_range = [0, 100,0 ,0];
          }
     
          if (col.type == "colorsurface") {
            column_range = [0, 0];
          }
        
          new_row[col.field] = column_range;
        }
      });
      (this as any).tableData.unshift(new_row);
      (this as any).add_default_class();
    },

    sortChange(params) {
      let sortField = undefined as any;
      Object.keys(params).forEach(key => {
        if (params[key] != "") {
          sortField = key;
        }
      });
      if (sortField == "name") {
        (this as any).tableData.sort((a, b) => {
          if (a["id"] == 0) {
            return 1; // default always at the end
          }
          if (params[sortField] === "asc") {
            return a[sortField] > b[sortField] ? 1 : -1;
          } else if (params[sortField] === "desc") {
            return b[sortField] < a[sortField] ? -1 : 1;
          }
        });
      } else {
        let column = (this as any).columns.filter(e => e.field == sortField)[0];
        if (column.type == "range" || column.type == "range_percentage" || column.type == "colorsurface"
        || column.type == "color") {
          (this as any).tableData.sort((a, b) => {
            if (a["id"] == 0) {
              return 1; // default always at the end
            }
            let min_a = parseFloat(a[sortField][0]);
            let min_b = parseFloat(b[sortField][0]);

            if (min_a == min_b) {
              min_a = parseFloat(a[sortField][1]);
              min_b = parseFloat(b[sortField][1]);
            }
            if (params[sortField] === "asc") {
              return min_a > min_b ? 1 : -1;
            } else if (params[sortField] === "desc") {
              return min_b < min_a ? -1 : 1;
            }
          });
        }
      }
    },

    get_active_columns(val) {
      let filtered_columns = [] as any;
      let columns = cloneDeep(val);
      for (let i in columns) {
        if (columns[i].active) filtered_columns.push(columns[i]);
      }
      return filtered_columns;
    },

    change_value(c, v) {
      if (c.type != "checkbox") {
        (this as any).selected_column = c;
        (this as any).selected_value = v;
        (this as any).dialog = true;
      }
    },

    get_eventCustomOption() {
      let change_value = (this as any).change_value;
      return {
        bodyCellEvents: ({ row, column, rowIndx }) => {
          return {
            click: event => {
              change_value(column, row);
            }
          };
        }
      };
    },

    set_selected_rows(selected) {
      (this as any).selected_rows = selected;
    },

    get_checkboxOption() {
      let set_selected_rows = (this as any).set_selected_rows;
      return {
        selectedRowChange: ({ row, isSelected, selectedRowKeys }) => {
          set_selected_rows(selectedRowKeys);
        },
        selectedAllChange: ({ isSelected, selectedRowKeys }) => {
          set_selected_rows(selectedRowKeys);
        }
      };
    },

    get_sortOption() {
      let sortChange = (this as any).sortChange;
      return {
        sortChange: params => {
          sortChange(params);
        }
      };
    }
  }
});
