
































































import Vue from "vue";
import { mapState, mapActions } from "vuex";
import { cloneDeep } from "lodash";
import {
  Clazz,
  Criterion,
  Program,
  DroppingRelation,
  ObjectCategoryProgram
} from "gs-proto/js/gs/proto/program_data_pb";
import { Field } from "gs-proto/js/gs/proto/categories_data_pb";
import { Filter} from "gs-proto/js/gs/proto/program_data_pb";

import { State } from "@/store/model";

import CancelSnackbar from "../CancelSnackbar.vue";
import ClassTable from "./ClassTable.vue";

import { ClazzTransformFunctions } from "./utils-js/clazz_transform_data";
import { MappingFunction } from "../../store/proto_utils/mapping";

export default Vue.extend({
  name: "classDialog",
  components: { CancelSnackbar, ClassTable },

  data: () => ({
    snackbar_cancel: false,
    dialog: false,
    show_mix: false,
    tableData: []
  }),

   props: {
    as_card: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState({
      current_program: state => (state as State).program,
      tmp_current_filters: state => (state as State).tmp_current_filters,
      websocket_connected: state => (state as State).ws_connected,
    })
  },

  watch: {
    dialog() {
      (this as any).update_table();
      (this as any).update_filters();
    },
    current_program() {
      (this as any).update_table();
    }
  },

  // watch:{
  //   websocket_connected(val){
  //     if (!val){
  //       (this as any).dialog = false
  //     }
  //   }
  // },
  methods: {
    ...mapActions(["create_or_update_program", "store_tmp_filters"]),

    update_table() {
      let table_c: any = (this as any).$refs.tableComponent as Vue;
      if (table_c) {
        table_c.mount();
      }
    },

    update_filters() {
      let filters:Filter[] = [];
      let filter_list = (this as any).current_program.objectCategoryProgramsList[0]['filtersList'];
      for (const f of filter_list) {
        filters.push(MappingFunction.filterToProto(f));
      }
      (this as any).store_tmp_filters(filters);  
    },


    update_tableData(new_Data) {
      (this as any).tableData = cloneDeep(new_Data);
    },

    cancel() {
      (this as any).snackbar_cancel = true;
    },

    continue_cancel() {
      (this as any).dialog = false;
    },

    save() {
        let tableComponent_component = (this as any).$refs.tableComponent ;
        if (tableComponent_component) {
          (this as any).tableData = tableComponent_component.tableData;
        }
      
      let clazzes: Clazz.AsObject[] = ClazzTransformFunctions.toProto(
        (this as any).tableData
      );
      let id_clazzes = clazzes.map(x => x.id);
      if ((this as any).current_program) {
        let updated_program: Program.AsObject = cloneDeep((this as any).current_program);
        updated_program.objectCategoryProgramsList[0].classesList = clazzes;
        let _program: Program = MappingFunction.programToProto(updated_program);
       _program.getObjectCategoryProgramsList()[0].setFiltersList((this as any).tmp_current_filters);
       
        (this as any).create_or_update_program(_program);
        (this as any).dialog = false;
      }
    }
  }
});
