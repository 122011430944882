




















































































import Vue from "vue";
import { mapState, mapActions } from "vuex";
import { cloneDeep } from "lodash";
import { State } from "@/store/model";
import { Package } from "gs-proto/js/gs/proto/program_data_pb";


export default {
  data() {
    return {
      valid: true,
      name: "",
      nameRules: [
        v => !!v || (this as any).$t("package.name_required"),
        v => (v && v.length <= 40) || (this as any).$t("package.name_rule")
      ],
      textRules: [
        v => v.length <= 40 || (this as any).$t("package.item_forty_chars")
      ],
      sizeRules: [v => v > 0 || (this as any).$t("package.bigger_0"), 
                  v => ( (this as any).size_type == "kg" || v == parseInt(v, 10) ) || (this as any).$t("package.must_be_integer") ],
      owner: "",
      client: "",
      size: 0,
      size_type: "kg",
      size_types: ["kg", (this as any).$t("package.pieces")],
      package_type: 0,
      packages: []
      // headers:
    };
  },
  computed: {
    ...mapState({
      all_packages: state => (state as State).packages as Package.AsObject[]
    }),
    headers() {
      return [
        {
          text: "Id",
          align: "start",
          value: "id"
        },
        {
          text: (this as any).$t("package.name"),
          value: "name"
        },
        { text: (this as any).$t("package.type"), value: "packageType" },
        { text: (this as any).$t("package.weight"), value: "weight" },
        { text: (this as any).$t("package.pieces"), value: "pieces" },
        { text: (this as any).$t("package.client"), value: "client" },
        { text: (this as any).$t("package.owner"), value: "owner" },
        { text: (this as any).$t("package.action"), value: "action" },
      ];
    },
    package_types() {
      let packages_map = (this as any).$store.getters.packagesMap;
      let p = [] as any;
      let idx = 0;
      for (let k in packages_map) {
        p.push({ id: idx, k: k, title: (this as any).$t("package." + k) });
        idx += 1;
      }
      return p;
    }
  },

  methods: {
    ...mapActions(["create_or_update_package", "delete_package"]),

    validate() {
      (this as any).$refs.form.validate();
      if ((this as any).$refs.form.validate()) {

        let new_package = new Package();
        if((this as any).package_id ==  ""){
            new_package.setId(0);
        } else {
            new_package.setId((this as any).package_id);
        }
       
        new_package.setName((this as any).name);
        new_package.setOwner((this as any).owner);
        new_package.setClient((this as any).client);
        new_package.setPackageType((this as any).package_type);

        if ((this as any).size_type == "kg") {
          new_package.setWeight((this as any).size);
        } else {
          new_package.setPieces((this as any).size);
        }

        (this as any).create_or_update_package(new_package);

        (this as any).reset_edit_form();
      }
    },
    reset() {
      (this as any).$refs.form.reset();
    },
    resetValidation() {
      (this as any).$refs.form.resetValidation();
    },
    load_item(id) {
      var current_packages = (this as any).all_packages.filter(x=>x.id ==id);
      (this as any).name = current_packages[0].name;
      (this as any).owner = current_packages[0].owner;
      (this as any).client = current_packages[0].client;
      (this as any).package_type = current_packages[0].packageType;
      (this as any).package_id = id;
      

      if (current_packages[0].weight > 0) {
          (this as any).size_type  = "kg";
          (this as any).size = current_packages[0].weight;
        } else {
          (this as any).size_type  = (this as any).$t("package.pieces");
          (this as any).size = current_packages[0].pieces;
        }
     
      (this as any).$refs.editor_header.innerHTML = (this as any).$t('package.update');
      (this as any).$refs.form_plate.style.backgroundColor = "rgb(247, 236, 210)";
      
    },
    delete_item(id) {
      var response = (this as any).delete_package(id);
      (this as any).reset_edit_form();
    },

      reset_edit_form() {
        (this as any).$refs.editor_header.innerHTML = (this as any).$t('package.create_new');
        (this as any).package_id = "";
        (this as any).name = "";
        (this as any).owner = "";
        (this as any).client = "";
        (this as any).size = 0;
        (this as any).size_type = "kg";
        (this as any).package_type = 0;
        (this as any).resetValidation();
        (this as any).$refs.form_plate.style.backgroundColor = "rgb(238, 245, 247)";
    },

  }
};
