
import paper from "paper";

function get_class_color() {
    return new paper.Color( "#161817")
}


function create_rectangle(event, scope, w, h) {
    let path
    path = new scope.Path.Rectangle(inside(event.downPoint,w,  h), inside(event.point, w, h))
    path.strokeColor = get_class_color()
    path.type = 'rectangle'
    path.fillColor = get_class_color()
    path.strokeWidth = w * 0.0015;
    return path
}

function inside(point, w, h){
    if (point.x < 0) {
        point.x = 0
    }else if (point.x > w){
        point.x = w
    }
    if (point.y < 0) {
        point.y = 0
    }else if (point.y > h){
        point.y = h
    }
    return point
}

const toolRect = (scope) => {
    const tool = new scope.Tool()
    tool.name = 'rectangle'

    tool.onMouseDrag = function (event) {
        let path = create_rectangle(event, this.scope, this.w, this.h)
        path.strokeColor.alpha = 1
        path.strokeWidth = 2
        path.fillColor.alpha = 0
        path.removeOn({
            drag: true,
            up: true
        })
    }
    tool.onMouseUp = function (event) {
        let path = create_rectangle(event, this.scope, this.w,  this.h)
        path.from = inside(event.downPoint, this.w, this.h)
        path.to = inside(event.point, this.w, this.h)
        path.strokeColor.alpha = 0.8
        path.strokeWidth = 2
        path.fillColor.alpha = 0.01
        path.selectedColor = new paper.Color( "#f1f6ff")
        if (path.area < 1) path.remove()
    }
    tool.setWH = function (scope, w, h) {
        this.scope = scope;
        this.w = w;
        this.h = h;
    }
    return tool
}

export default toolRect;
