


























































































































import Vue from "vue";
import paper from "paper";

import { cloneDeep } from "lodash";
import { mapState, mapActions } from "vuex";

import { Field } from "gs-proto/js/gs/proto/categories_data_pb";
import { Filter, Polygon, Point } from "gs-proto/js/gs/proto/program_data_pb";

import { State } from "@/store/model";

import ToolStack from "./tools/ToolStack.js";
import toolEdit from "./tools/Edit";
import toolRect from "./tools/Rectangle";
import toolPolygon from "./tools/Polygon";
import toolEraser from "./tools/Eraser";
import toolMove from "./tools/Move";

import LastColors from "./LastColors.vue";

export default Vue.extend({
  name: "colorPicker",
  components: {
    LastColors
  },
  data: () => ({
    dialog: false,
    toolStack: undefined as any,
    activated_tool: undefined as any,
    tool_items: [
      {
        id: "rectangle",
        icon: "mdi-vector-rectangle",
        tooltip: "Rectangle"
      },
      {
        id: "polygon",
        icon: "mdi-vector-polygon",
        tooltip: "Polygon"
      },
      {
        id: "edit",
        icon: "mdi-pencil",
        tooltip: "Edit"
      },

      {
        id: "move",
        icon: "mdi-arrow-all",
        tooltip: "Move"
      },
      {
        id: "eraser",
        icon: "mdi-eraser"
      }
    ],

    canvas_size: 400,
    canvas_size_width: 580,

    s_min: 0,
    s_max: 100,
    s_range: [0, 100],

    field_id: undefined,
    started: false,
    filter_done: false,
    scope: undefined,
    closed: false,
    show_error: false
  }),

  props: {
    filterColumn: {
      type: Object
    }
  },
  computed: {
    ...mapState({
      current_program: state => (state as State).program || { id: 0 },
      tmp_current_filters: state =>
        (state as State).tmp_current_filters as Filter.AsObject[]
    })
  },

  updated() {
    const elem = this.$refs.rectangle;
    if (elem) {
      if (!this.started) {
        elem[0].$el.click();
      }
    }
  },

  mounted() {
    this.field_id = this.filterColumn.id;
    (this as any).scope = new paper.PaperScope();

    (this as any).tmp_current_filters.forEach(f => {
      if (f.getField() == this.field_id) {
        (this as any).filter_done = true;
      }
    });
  },

  watch: {
    dialog(v) {
      if (v && this.closed) {
        (this as any).load_previous_filter();
      }
    }
  },

  methods: {
    ...mapActions(["store_tmp_filters"]),
    any() {
      let all_paths = (this as any).scope.project.getItems({
        class: (this as any).scope.Path
      });
      all_paths.forEach(p => {
        p.remove();
      });

      let segments = [
        [0, 0],
        [0, (this as any).canvas_size],
        [(this as any).canvas_size_width, (this as any).canvas_size],
        [(this as any).canvas_size_width, 0]
      ];
      let path = new (this as any).scope.Path(segments);
      path["type"] = "polygon";
      path.selectedColor = new (this as any).scope.Color("#f1f6ff");
      path.fillColor = new (this as any).scope.Color("#161817");
      path.closed = true;
      path.fillColor.alpha = 0.01;
      path.strokeColor = new (this as any).scope.Color("#161817");
      path.strokeWidth = 3;
    },
    paper_canvas() {
      return <HTMLCanvasElement>(
        document.getElementById(
          "paper_canvas_filter_" +
            (this as any).field_id +
            "_" +
            (this as any).current_program.id
        )
      );
    },

    load_previous_filter() {
      // LOAD PREVIOUS FILTER
      let w = (this as any).canvas_size_width;
      let h = (this as any).canvas_size;
      let founded = false;
      let paper_canvas = this.paper_canvas();
      (this as any).scope.setup(paper_canvas);

      (this as any).tmp_current_filters.forEach(f => {
        if (f.getField() == this.field_id) {
          founded = true;
          (this as any).s_range = [f.getMinSaturation(), f.getMaxSaturation()];
          if (f.getPolygonsList().length == 0) {
            return false;
          }
          f.getPolygonsList().forEach(pl => {
            let segments = [] as any;
            pl.getPointsList().forEach(p => {
              segments.push([p.getX() * w, p.getY() * h]);
            });
            let path = new (this as any).scope.Path(segments);
            path["type"] = "polygon";
            path.selectedColor = new (this as any).scope.Color("#f1f6ff");
            path.fillColor = new (this as any).scope.Color("#161817");
            path.closed = true;
            path.fillColor.alpha = 0.01;
            path.strokeColor = new (this as any).scope.Color("#161817");
            path.strokeWidth = 3;
          });
        }
      });
      return founded;
    },
    activate(id) {
      (this as any).show_error = false;
      if (!this.started) {
        this.draw_rectangle();
        let paper_canvas = this.paper_canvas();
        (this as any).scope.setup(paper_canvas);
        this.started = true;
        (this as any).load_previous_filter();
        this.toolStack = new ToolStack(
          [toolEdit, toolRect, toolEraser, toolMove, toolPolygon],
          (this as any).scope
        );
      }

      (this as any).toolStack.activateTool(
        id,
        (this as any).scope,
        (this as any).canvas_size_width,
        (this as any).canvas_size
      );
      (this as any).activated_tool = id;
    },

    save() {
      let filter_is_empty = true;
      let _tmp_filters = cloneDeep((this as any).tmp_current_filters);
      let override = false;

      _tmp_filters.forEach(f => {
        if (f.getField() == this.field_id) {
          override = true;
          f.setMaxSaturation((this as any).s_range[1]);
          f.setMinSaturation((this as any).s_range[0]);
          let paths = (this as any).get_paths();
          filter_is_empty = paths.length < 1;
          f.setPolygonsList(paths);
        }
      });

      if (!override) {
        let new_filter = new Filter();
        new_filter.setId((this as any).field_id);
        new_filter.setField((this as any).field_id);
        new_filter.setMaxSaturation((this as any).s_range[1]);
        new_filter.setMinSaturation((this as any).s_range[0]);
        let paths = (this as any).get_paths();
        filter_is_empty = paths.length < 1;
        new_filter.setPolygonsList(paths);
        _tmp_filters.push(new_filter);
      }

      this.store_tmp_filters(_tmp_filters);
      (this as any).filter_done = _tmp_filters.length > 0;
      let all_paths = (this as any).scope.project.getItems({
        class: (this as any).scope.Path
      });

      if (all_paths.length > 0) {
        all_paths.forEach(p => {
          p.remove();
        });
      }
      if (filter_is_empty) {
        (this as any).show_error = true;
      } else {
        (this as any).show_error = false;
        (this as any).dialog = false;
        (this as any).closed = true;
      }
    },

    get_paths() {
      let w = (this as any).canvas_size_width;
      let h = (this as any).canvas_size;
      let canvas_size = {
        width: w,
        height: h
      };

      let polygon_list = new Array<Polygon>();

      let paths = (this as any).scope.project
        .getItems({ class: paper.Path })
        .filter(x => x["area"] != 0);

      paths.forEach(el => {
        let polygon = new Polygon();
        let point_list = new Array<Point>();
        el["segments"].forEach(s => {
          let p = new Point();
          p.setX(s["point"]["x"] / canvas_size.width);
          p.setY(s["point"]["y"] / canvas_size.height);
          point_list.push(p);
        });
        // el.remove();
        polygon.setPointsList(point_list);
        polygon_list.push(polygon);
      });
      return polygon_list;
    },

    draw_rectangle() {
      let colorCanvas3 = <HTMLCanvasElement>(
        document.getElementById(
          "color_canvas_filter_" +
            (this as any).field_id +
            "_" +
            (this as any).current_program.id
        )
      );

      colorCanvas3 = <HTMLCanvasElement>this.$refs["color_canvas_filter"];
      if (colorCanvas3) {
        let ColorCtx = colorCanvas3.getContext("2d"); // This create a 2D context for the canvas
        if (ColorCtx) {
          ColorCtx.beginPath();
          let color = "hsl(0, 100%, 50%)";
          let gradientH = ColorCtx.createLinearGradient(
            0,
            0,
            ColorCtx.canvas.width,
            0
          );
          gradientH.addColorStop((1 / 6) * 0, "#00ffff");
          gradientH.addColorStop((1 / 6) * 1, "#0000ff");
          gradientH.addColorStop((1 / 6) * 2.2, "#ff00ff");
          gradientH.addColorStop((1 / 6) * 3, "#ff0000");
          gradientH.addColorStop((1 / 6) * 4.2, "#ffff00");
          gradientH.addColorStop((1 / 6) * 5, "#00ff00");
          gradientH.addColorStop((1 / 6) * 6, "#00ffff");
          // gradientH.addColorStop(1, color);
          ColorCtx.fillStyle = gradientH;
          ColorCtx.fillRect(
            0,
            0,
            ColorCtx.canvas.width,
            ColorCtx.canvas.height
          );

          // Create a Vertical Gradient(white to black)
          let gradientV = ColorCtx.createLinearGradient(
            0,
            0,
            0,
            (this as any).canvas_size
          );
          gradientV.addColorStop(1, "rgba(255,255,255,0.97)");
          gradientV.addColorStop(0.75, "rgba(255,255,255, 0)");
          gradientV.addColorStop(0.5, "rgba(50,50,50, 0.05)");
          gradientV.addColorStop(0.25, "rgba(50,50,50, 0.7)");
          gradientV.addColorStop(0, "rgba(10,10,10,1)");
          ColorCtx.fillStyle = gradientV;
          ColorCtx.fillRect(
            0,
            0,
            ColorCtx.canvas.width,
            ColorCtx.canvas.height
          );
        }
        return true;
      }
      return false;
    }
  }
});
