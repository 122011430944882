




















































































import Vue from "vue";
import { mapState, mapActions } from "vuex";
import { Exit} from "gs-proto/js/gs/proto/machine_data_pb";
import { State } from "@/store/model";
import { ExitsUtils } from "@/components/distribution/utils/exits_utils";

export default ({
  data() {
    return {
      max_slider_weight: 1000, //TODO
      max_slider_pieces: 100, //TODO
      unit_weight: 'kg',
      unit_pieces: 'pieces',
      dialog: false,
      new_accumulatevalue:0.0,
      new_accumulatetype:undefined,
     
    };
  },
  props: {
    exit_id: {
      type: Number
    },
    only_icon :{
      type:Boolean,
      default: false
    }
  },
  mounted() {
    let v = (this as any).listExits.filter(x=>x.id == (this as any).exit_id)[0] as Exit.AsObject;
    (this as any).new_accumulatevalue= v.accumulateValue as Number;
    (this as any).new_accumulatetype = v.accumulateType;
    (this as any).unit_pieces = (this as any).$t("distribution.pieces");
  },
  computed: {
      ...mapState({
        listExits:  state => (state as State).machine?.exitsList as Exit.AsObject[]
      }),
       acc_types()
      {return [
        {id:0, title: (this as any).$t("distribution.weight"), icon:'mdi-weight'} ,
        {id:1, title: (this as any).$t("distribution.pieces"), icon:'mdi-food-apple-outline'},
      ]},
      exit(){
          return (this as any).listExits.filter(x=>x.id == (this as any).exit_id)[0] as Exit.AsObject;
    }
  },

  methods:{
    ...mapActions(["change_exit_accumulate"]),
    change_select(){
       (this as any).new_accumulatevalue = parseInt( (this as any).new_accumulatevalue);
    },

    get_acc_type(e) {
      return (this as any).acc_types.filter(x=>x.id == e.accumulateType)[0];
    },

    toggle() {
      let _ExitChange = ExitsUtils.changeAccumulation( (this as any).exit, (this as any).new_accumulatevalue, (this as any).new_accumulatetype);
      (this as any).change_exit_accumulate(_ExitChange);
      (this as any).dialog = false
    },
    cancel(){
       (this as any).dialog = false
    }
  }
});
